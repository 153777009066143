<div class="modal-body">
    <div class="app-modal-header">
        <h5 class="modal-title">Fix component version</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="$event.stopPropagation();closeBtn()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" *ngIf="!loading">
        <div *ngIf="patchedVersions">
            <p><b>Fix vulnerabilities in version <span class="badge m-r-5 badge-light-danger">{{oldVersion}}</span></b></p>
            <p>Choose version:</p>

            <div class="form-group">
              <label class="radio radio-primary d-inline">
                <input type="radio" name="latest-version" [value]="patchedVersions.latestPatchedVersion" [(ngModel)]="newVersion">
                <span class="cr">
                  <ng-container *ngIf="patchedVersions.nextPatchedVersion !== patchedVersions.latestPatchedVersion; else onlyVersion">
                    Update to latest secure version
                    <span class="badge badge-light-success">{{ patchedVersions.latestPatchedVersion }}</span>
                  </ng-container>

                  <ng-template #onlyVersion>
                    Upgrade to the only secure version
                    <span class="badge badge-light-success">{{ patchedVersions.latestPatchedVersion }}</span> available.
                  </ng-template>
                </span>
              </label>
            </div>

            <div class="form-group" *ngIf="patchedVersions.nextPatchedVersion !== patchedVersions.latestPatchedVersion">
              <label class="radio radio-primary d-inline">
                <input type="radio" name="next-version" [value]="patchedVersions.nextPatchedVersion" [(ngModel)]="newVersion">
                <span class="cr">
                  Update to next secure version <span class="badge badge-light-success">{{ patchedVersions.nextPatchedVersion }}</span>
                  (lowest risk of breaking build)
                </span>
              </label>
            </div>
            <ng-template #noSameTempate>
                <div class="form-group">
                    <label class="radio radio-primary d-inline">
                        <input [(ngModel)]="newVersion" type="radio" value="{{patchedVersions.latestPatchedVersion.version}}"
                            name="{{componentId}}-latest" id="versionRedio1">
                        <span for="versionRedio1" class="cr">Update to latest secure version <span
                                class="badge m-r-5 badge-light-success">{{patchedVersions.latestPatchedVersion.version}}</span></span>
                    </label>
                </div>
                <div class="form-group">
                    <label class="radio radio-primary d-inline">
                        <input [(ngModel)]="newVersion" type="radio" value="{{patchedVersions.nextPatchedVersion.version}}"
                            name="{{componentId}}-next" id="versionRedio2">
                        <span for="versionRedio2" class="cr">Update to next secure version <span
                                class="badge m-r-5 badge-light-success">{{patchedVersions.nextPatchedVersion.version}}</span>(lowest
                            risk of breaking build)</span>
                    </label>
                </div>
            </ng-template>

        </div>
        <div *ngIf="!patchedVersions">
            Patched version was not found
        </div>
    </div>
    <div class="app-modal-body" *ngIf="loading">
        Loading...
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary m-r-5" data-dismiss="modal" (click)="$event.stopPropagation();closeBtn()">Close</button>
        <button *ngIf="patchedVersions && newVersion" type="button" class="btn btn-primary  m-r-5" [disabled]="!newVersion || newVersion === ''"(click)="$event.stopPropagation(); closeBtn(); fixVersion()">Fix</button>
    </div>
</div>
