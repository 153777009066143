import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-metrics-chip',
  templateUrl: './metrics-chip.component.html',
  styleUrls: ['./metrics-chip.component.scss'],
})
export class MetricsChipComponent {
  @Input() color:
    | 'red'
    | 'bright-red'
    | 'blue'
    | 'green'
    | 'orange'
    | 'grey'
    | 'yellow';
  @Input() value: number;
  @Input() title: string;
  @Input() review: boolean;
}
