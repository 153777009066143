import { License } from './license';
import {
  EntityMetricsGroup,
  EntityMetricsSummaryGroup,
  LicenseMetrics,
} from './metrics';
import { ProjectConnection } from './project';
import { Vulnerability } from './vulnerability';
import { Injectable } from '@angular/core';
import { PageInfo } from '@app/models/paging';

export class Entity {
  entityId: string;
  parentEntityId: string;
  entitySubType: string;
  name: string;
  projects: ProjectConnection;
  entityMetricsGroup: EntityMetricsGroup;
  entityMetricsSummaryGroup: EntityMetricsSummaryGroup;
  vulnerabilities: Vulnerability[];
  licenses: License[];
  childEntities: {
    edges: Array<{ node: Entity }>;
  };
  childEntityList: Array<Entity>;
  entitySettings: EntitySettings;
  parents?: Array<any>;
  entityType: string;
  scpProjectMetrics?: ScpProjectMetrics;
  latestScanByScpProject?: {
    created: string;
  };
}

export interface ScpProjectMetrics {
  vulnerable_components: {
    H: number;
    M: number;
    L: number;
    C: number;
    I?: number;
  };
  asset_composition: {
    E: number;
    O: number;
    OS: number;
  };
  vulnerabilities: {
    H: number;
    M: number;
    L: number;
    C: number;
    I?: number;
  };
  review_status: {
    I: number;
    R: number;
    P: number;
    percent: number;
    A: number;
  };
  licences: LicenseMetrics;
}

export interface EntityNodeWrapper {
  level: number;
  node: EntityNode;
  parent: any;
  visible: boolean;
}

export interface EntityNode {
  children: Array<any>;
  data: Entity;
  id: string;
  name: string;
  parent: any;
  parentId: string;
}

export class EntityConnection {
  edges: EntityEdge[];
  pageInfo: PageInfo;
  totalCount: number;
  constructor() {}
}

export class EntityEdge {
  node: Entity;
  cursor: string;
  constructor(node: Entity, cursor: string) {
    this.node = node;
    this.cursor = cursor;
  }
}

export type EntityQuery = {
  entity: Entity;
};

export type EntityArray = {
  entities: Entity[];
};

export type EntityListByOrgArray = {
  entityListByOrg: Entity[];
};

export type EntityListQuery = {
  entities: EntityConnection;
};

export class JiraCredentials {
  projectUrl: string;
  projectId: string;
  issueTypeId: string;
  email: string;
  apiToken: string;
}

@Injectable()
export class EntitySettings {
  orgId: string;
  entityId: string;
  alertEmailAdressess: string[];
  alertSlackUrls: string[];
  jiraCredentials: JiraCredentials;
  preferSavedAssetContentForDragDropScan: boolean;
  policyActionsOnNewArtifactsOnly: boolean;
  dropRejectedLicenses: boolean;
  minMatchPct: number;
  minMatchScore: number;
  minMatchSize: number;
  reportOnlyWholeAssetMatches: boolean;
  useAggressiveOriginGrouping: boolean;
  reportInlineScripts: boolean;
  reportCDNScripts: boolean;
}

export type EntitySettingsQuery = {
  entitySettings: EntitySettings;
};

export type DeleteProjectQuery = {
  deleteProject: DeleteResult;
  detachProject: DeleteResult;
};

export type DeleteSCProjectQuery = {
  deleteSCProject: DeleteResult;
};

export type DeleteResult = {
  status: boolean;
};
