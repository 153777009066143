<perfect-scrollbar
  id="nav-ps-next"
  class="next next-scroll"
  [style.max-width.px]="300"
  [style.max-height]="'calc(100vh - 60px)'"
  [usePSClass]="true"
  [excludeBeforeClick]="true"
  [disabled]="null"
  *ngIf="this.nextConfig['layout'] === 'vertical'"
  (clickOutside)="navMob()"
>
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <app-card [hidHeader]="true" cardClass="text-center">
      <i class="feather icon-sunset f-40"></i>
      <h6 class="mt-3">Help?</h6>
      <p>Please contact us on our email for need any support</p>
      <a
        href="javascript:"
        target="_blank"
        class="btn btn-primary btn-sm text-white m-0"
        >Support</a
      >
    </app-card>
  </div>
</perfect-scrollbar>

<div class="logo_menu_box">
  <div class="m-header">
    <a
      href="javascript:"
      class="mobile-menu"
      id="mobile-collapse"
      (click)="navCollapse()"
      ><span></span
    ></a>
    <a [routerLink]="['/security/entity']" class="b-brand">
      <img
        id="main-logo"
        src="assets/images/logo-app-350px.svg"
        alt=""
        class="logo"
        *ngIf="
          this.nextConfig.navBrandColor !== 'brand-default' ||
          this.nextConfig.headerBackColor !== 'header-default'
        "
      />
      <img
        src="assets/images/logo-dark.png"
        alt=""
        class="logo"
        *ngIf="
          this.nextConfig.navBrandColor === 'brand-default' &&
          this.nextConfig.headerBackColor === 'header-default'
        "
      />
      <!-- this was throwing a 404
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
      -->
    </a>
    <div class="threat-center">Threat Center</div>
    <!-- <a
      class="mob-toggler"
      [ngClass]="{ on: this.menuClass }"
      href="javascript:"
      (click)="toggleMobOption()"
      ><i class="feather icon-more-vertical"></i
    ></a> -->
  </div>

  <div
    *ngIf="this.nextConfig['layout'] === 'horizontal'"
    #navbarWrapper
    class="navbar-content sidenav-horizontal"
    id="layout-sidenav"
  >
    <a
      href="javascript:"
      class="sidenav-horizontal-prev"
      [ngClass]="prevDisabled"
      (click)="scrollMinus()"
    ></a>
    <div class="sidenav-horizontal-wrapper">
      <!-- add 14-4 viral -->
      <ul
        #navbarContent
        id="side-nav-horizontal"
        class="nav pcoded-inner-navbar sidenav-inner"
        (clickOutside)="fireLeave()"
        (mouseleave)="fireLeave()"
      >
        <ng-container *ngFor="let item of navigation">
          <app-nav-group
            *ngIf="item.type == 'group'"
            [item]="item"
          ></app-nav-group>
          <app-nav-collapse
            *ngIf="item.type == 'collapse'"
            [item]="item"
          ></app-nav-collapse>
          <app-nav-item
            *ngIf="item.type == 'item'"
            [item]="item"
          ></app-nav-item>
        </ng-container>
      </ul>
    </div>
    <!-- <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>-->
  </div>

  <div class="userimagebox">
    <div>
      <!-- <app-nav-left class="mr-auto"></app-nav-left> -->
      <app-nav-right class="ml-auto"></app-nav-right>
    </div>
  </div>
</div>
