import { Component, OnInit } from '@angular/core';
import { SCPProjectsById } from '@app/compliance-dashboard-new/store/project-summary/project-summary.state';
import { Project } from '@app/models';
import { AlertService } from '@app/services/core';
import { LocalStorageService } from '@app/services/local-storage.service';
import { SupplyChainProjectService } from '@app/services/supply-chain-project.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApolloQueryResult } from 'apollo-client';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, debounceTime, finalize, take } from 'rxjs/operators';
import { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-scp-projects-move-dialog',
  templateUrl: './scp-projects-move-dialog.component.html',
  styleUrls: ['./scp-projects-move-dialog.component.scss'],
})
export class ScpProjectsMoveDialogComponent implements OnInit {
  public scpProjects: Array<SCPProjectsById>;
  public entityName: string;
  public entityId: string;
  public entitySubType: string;
  public project: Project;
  public loading: boolean;
  public projectSearchName: string;
  public isMovingModuleLoading: boolean;

  public constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private sCPService: SupplyChainProjectService,
    private alertService: AlertService,
    private localStorage: LocalStorageService
  ) {}

  public ngOnInit(): void {
    this.getScpProjects();
  }

  public closeDialog(): void {
    this.activeModal.close(this.isMovingModuleLoading);
  }

  public getScpProjects(name = ''): void {
    this.loading = true;

    this.sCPService
      .getScpProjects(name)
      .pipe(
        debounceTime(400),
        finalize(() => (this.loading = false)),
        catchError((error) => {
          this.loading = false;
          return of();
        }),
        take(1)
      )
      .subscribe(
        (
          data: ApolloQueryResult<{
            scpProjectsGroupedByEntities: Array<SCPProjectsById>;
          }>
        ) => {
          this.scpProjects = data.data.scpProjectsGroupedByEntities;
        }
      );
  }

  public moveProject(newSCPID: string, incompatible?: boolean): void {
    if (incompatible) {
      return;
    }
    this.isMovingModuleLoading = true;
    this.alertService
      .alertConfirm(
        'Are you sure you want to move this module?',
        `You are going to move the module to a different project, proceed?`,
        'warning',
        true,
        true,
        '#4680ff',
        '#6c757d',
        'Ok',
        'Cancel'
      )
      .then((move: SweetAlertResult) => {
        if (move.value) {
          this.sCPService
            .moveModule(this.project.projectId, this.entityId, newSCPID)
            .pipe(
              finalize(() => (this.isMovingModuleLoading = false)),
              catchError((error) => {
                this.toastrService.error(
                  'There was an error moving the module'
                );
                this.isMovingModuleLoading = false;
                return of();
              }),
              take(1)
            )
            .subscribe((data: any) => {
              // fire and forget
              this.localStorage.deleteMovingModule();
            });
          this.activeModal.close(true);
          this.toastrService.success(
            `The process has been initiated. It could take some time. 
              After the process is finished, the page will be reloaded.`
          );
          this.localStorage.setMovingModule(this.project.projectId);
        } else {
          this.isMovingModuleLoading = false;
        }
      });
  }
}
