import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '@app/security/services';
import { AlertService } from '@app/services/core/alert.service';

import { environment } from 'environments/environment';
import { MESSAGES } from '@app/messages/messages';

@Injectable({
  providedIn: 'root',
})
export class CoreErrorHelperService {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}

  //Below Method will going to handle network errors if any.
  async handleNetworkError(errObj: HttpErrorResponse, requestPayload: any) {
    if (!errObj || errObj.status === null || errObj.status === undefined) {
      this.alertService.alertBox(
        MESSAGES.ERROR_MESSAGE,
        MESSAGES.ERROR_TITLE,
        'error'
      );
    } else {
      // Getting server error if any other wise show default message according to status of server
      const dataObjToShow: {
        status: number | string;
        message: string;
      } = {
        status: MESSAGES.ERROR_TITLE,
        message: this.getDefaultErrorMessageFromServerIf(errObj),
      };

      switch (errObj.status) {
        case 0:
          if (errObj.error) {
            dataObjToShow.message = errObj.error;
          }

          await this.redirectUserToLoginPage(dataObjToShow.message);

          break;

        case 401:
          await this.redirectUserToLoginPage(dataObjToShow.message);

          break;

        case 403:
          const jwt = this.authenticationService.getFromCookie('jwt');

          // check if JWT is expired
          if (jwt) {
            if (this.authenticationService.isTokenExpired(jwt)) {
              // redirect user with notifying
              await this.redirectUserToLoginPage(dataObjToShow.message);
            } else {
              this.alertService.alertBox(
                dataObjToShow.message,
                MESSAGES.ERROR_TITLE,
                'error'
              );
            }
          } else {
            // if no JWT then redirect user with notifying
            await this.redirectUserToLoginPage(MESSAGES.TOKEN_NOT_FOUND);
          }

          break;

        case 400:
          if (Array.isArray(errObj.error.errors)) {
            let msg: string = '';
            errObj.error.errors.forEach((element) => {
              msg += '<br/>';
              msg +=
                element.field.replace(/\b\w/g, (l) => l.toUpperCase()) +
                ' ' +
                element.defaultMessage +
                '.';
            });
            this.alertService.alertBoxHtml(msg, errObj.error.message, 'error');
          } else {
            this.alertService.alertBox(
              dataObjToShow.message,
              MESSAGES.ERROR_TITLE,
              'error'
            );
          }
          break;
        default:
          // Rest of all status code perform over here
          if (errObj.status === 502) {
            this.alertService.alertBox(
              MESSAGES.STATUS_502,
              'Server is restarting',
              'error'
            );
          } else {
            if (errObj.status === 500) {
              dataObjToShow.message = 'Invalid username or password!';
            }
            this.alertService.alertBox(
              dataObjToShow.message,
              MESSAGES.ERROR_TITLE,
              'error'
            );
          }
          break;
      }
      this.printErrorMessageToConsole(dataObjToShow.message);
    }
  }

  /** Helper function to print error in console if any from server side */
  printErrorMessageToConsole(message: any) {
    // Print error in console when system is in development mode
    if (!environment.production && !environment.staging) {
      console.error(message);
    }
  }

  /** Get messages according to status */
  private getMessageStatusWise(status: number) {
    return MESSAGES[`STATUS_${status}`] || MESSAGES.ERROR_MESSAGE;
  }

  //Get default error message from server if any
  private getDefaultErrorMessageFromServerIf(errObj: HttpErrorResponse) {
    let errorMessage: string = '';
    //getting error message from server if any available otherwise get default message according to status code
    if (typeof errObj.error === 'string') {
      const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
      if (isHTML(errObj.error)) {
        errorMessage = this.getMessageStatusWise(Number(errObj.status));
      } else {
        errorMessage = errObj.error;
      }
    } else if (
      !!errObj.error &&
      !!errObj.error.reason &&
      typeof errObj.error.reason === 'string'
    ) {
      errorMessage = errObj.error.reason;
    } else if (
      !!errObj.error &&
      !!errObj.error.errorMessage &&
      typeof errObj.error.errorMessage === 'string'
    ) {
      errorMessage = errObj.error.errorMessage;
    } else if (
      !!errObj.error &&
      !!errObj.error.message &&
      typeof errObj.error.message === 'string'
    ) {
      errorMessage = errObj.error.message;
    } else {
      errorMessage = this.getMessageStatusWise(Number(errObj.status));
    }
    return errorMessage;
  }

  /**
   * Helper function which will redirect user to login page with notifying user.
   *
   * @param message redirect error message
   */
  private async redirectUserToLoginPage(message: string) {
    if (this.router.url.indexOf('/login') > -1) {
      return;
    }
    await this.authenticationService.logout();
  }

  /**
   * Core error handler
   *
   * @param errorSource error source path
   * @param requestPayload optional request payload for interceptor
   * @param consoleError optional custom console error message
   * @param alert optional alert
   * @param error HTTP response error
   * @param source source observable
   *
   * @returns silent empty observable
   */
  errorHandler(
    errorSource: string,
    requestPayload: any,
    consoleError: string,
    alert: Partial<{
      title: string;
      text: string;
      hasHTML: boolean;
    }>,
    error: HttpErrorResponse | any,
    source$: Observable<any>
  ) {
    if (consoleError) {
      this.printErrorMessageToConsole(consoleError);
    }

    const jwt = this.authenticationService.getFromCookie('jwt');
    const isTokenExpired = this.authenticationService.isTokenExpired(jwt);

    if (!isTokenExpired && alert) {
      const showAlert = alert.hasHTML
        ? this.alertService.alertBoxHtml
        : this.alertService.alertBox;

      showAlert(alert.text, alert.title, 'error');
    }

    // GraphQL uses network error
    if (requestPayload || error?.networkError) {
      this.handleNetworkError(error?.networkError ?? error, requestPayload);
    }

    return throwError(error);
  }
}
