import { Component, Input, OnInit } from '@angular/core';
import { StringSearchService } from '@app/services/string-search.service';
import { StringSearchSettings } from '@app/models/string-search-setting';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'app-string-search-settings',
  templateUrl: './string-search-settings.component.html',
  styleUrls: ['./string-search-settings.component.scss'],
})
export class StringSearchSettingsComponent implements OnInit {
  @Input() public objectId: string = null;
  @Input() public level: string = 'ORGANIZATION';

  public stringSearchPattern: string;
  public stringSearchDescription: string;
  public hasError: boolean = false;
  public stringSearchSettingsList: Array<StringSearchSettings>;
  public selectedIndex: number = -1;

  public constructor(
    private stringSearchService: StringSearchService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.loadStringSearchSettingsForLevel();
  }

  public saveStringSearchSettings(): void {
    if (
      this.stringSearchPattern === undefined ||
      this.stringSearchPattern === '' ||
      this.stringSearchPattern === null
    ) {
      this.hasError = true;
      return;
    }
    this.hasError = false;

    this.stringSearchService
      .saveStringSearchSettings(
        this.stringSearchPattern,
        this.stringSearchDescription,
        this.objectId,
        this.level
      )
      .pipe(
        take(1),
        catchError((error: Error) => {
          this.toastr.success(
            'There was an error adding String Search Settings.'
          );
          this.stringSearchPattern = '';
          this.stringSearchDescription = '';
          return of();
        })
      )
      .subscribe((res: any) => {
        if (res && res.data && res.data.saveStringSearchSetting) {
          this.toastr.success('String Search Settings added successfully.');
          this.stringSearchSettingsList.push({
            pattern: this.stringSearchPattern,
            description: this.stringSearchDescription,
            objectId: this.objectId,
            level: this.level,
          });
          this.stringSearchPattern = '';
          this.stringSearchDescription = '';
        }
      });
  }

  public deleteStringSearchSettings(settings: StringSearchSettings) {
    this.stringSearchService
      .deleteStringSearchSettings(settings)
      .pipe(
        take(1),
        catchError((error: Error) => {
          this.toastr.success(
            'There was an error deleting String Search Settings.'
          );
          return of();
        })
      )
      .subscribe((res: any) => {
        if (!!res && !!res.data) {
          if (res.data.deleteStringSearchSetting) {
            // deleted
            this.toastr.success('String Search Settings deleted successfully.');
          } else {
            // not deleted
            this.toastr.error('Failed to delete String Search Settings.');
          }

          this.loadStringSearchSettingsForLevel();
        }
      });
  }

  private loadStringSearchSettingsForLevel() {
    this.stringSearchService
      .stringSearchSettingsForLevel(this.objectId, this.level)
      .pipe(
        take(1),
        catchError((error: Error) => of())
      )
      .subscribe((res: any) => {
        if (res && res.data) {
          this.stringSearchSettingsList = res.data.stringSearchSettingsForLevel;
        }
      });
  }

  public editStringSearchSettings(rowIndex: number): void {
    this.selectedIndex = rowIndex;
  }

  public updateStringSearchSettings(rowIndex: number): void {
    const keyPattern = (
      document.getElementById(
        'stringSearchPatternKey-' + rowIndex
      ) as HTMLInputElement
    ).value;
    const newPattern = (
      document.getElementById(
        'stringSearchPattern-' + rowIndex
      ) as HTMLInputElement
    ).value;
    const newDescription = (
      document.getElementById(
        'stringSearchDescription-' + rowIndex
      ) as HTMLInputElement
    ).value;

    // save new settings first
    this.stringSearchService
      .saveStringSearchSettings(
        newPattern,
        newDescription,
        this.objectId,
        this.level
      )
      .pipe(
        take(1),
        catchError((error: Error) => of())
      )
      .subscribe((res: any) => {
        if (!!res && !!res.data && !!res.data.saveStringSearchSetting) {
          // this.toastr.success('String Search Settings added successfully.');
          // this.loadStringSearchSettingsForLevel();
          // this.stringSearchPattern = '';
          // this.stringSearchDescription = '';

          // if pattern value has not been changed then do not delete ord record bcs pattern is a part of a key
          // thus original record will be overwritten and then will be deleted
          if (keyPattern === newPattern) {
            this.selectedIndex = -1;
            return;
          }

          const settings2delete = new StringSearchSettings(
            keyPattern,
            null,
            this.objectId,
            this.level
          );
          // then delete old
          this.stringSearchService
            .deleteStringSearchSettings(settings2delete)
            .subscribe((res: any) => {
              if (!!res && !!res.data) {
                if (res.data.deleteStringSearchSetting) {
                  // deleted
                  this.toastr.success(
                    'String Search Settings updated successfully.'
                  );
                } else {
                  // not deleted
                  this.toastr.error(
                    'Failed to delete old String Search Settings.'
                  );
                }

                this.loadStringSearchSettingsForLevel();
              }
            });
          // -- delete
        } // -- successful save

        // invalidate selected index after update
        this.selectedIndex = -1;
      });

    this.selectedIndex = -1;
  }

  public cancelEditStringSearchSettings(rowIndex: number): void {
    const keyPattern: string = (
      document.getElementById(
        'stringSearchPatternKey-' + rowIndex
      ) as HTMLInputElement
    ).value;
    const originalDescription: string = (
      document.getElementById(
        'stringSearchDescriptionOriginal-' + rowIndex
      ) as HTMLInputElement
    ).value;

    (
      document.getElementById(
        'stringSearchPattern-' + rowIndex
      ) as HTMLInputElement
    ).value = keyPattern;
    (
      document.getElementById(
        'stringSearchDescription-' + rowIndex
      ) as HTMLInputElement
    ).value = originalDescription;

    this.selectedIndex = -1;
  }
}
