import { Injectable } from '@angular/core';
import { AlertService, CoreGraphQLService } from './core';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { LicenseAssetAttribution } from '@app/models/scan';
import { ToastrService } from 'ngx-toastr';
import { User } from '@app/models';
import { OngoingOperationsService } from './ongoing-operations.service';

export interface AttributionProcess {
  statusMessage: string;
  status: 'RUNNING' | 'COMPLETE' | 'ERROR';
  result: LicenseAssetAttribution;
}

export interface AnnotationStorageInfo {
  currentUserName: string;
  taskToken: string;
  licenseId: string;
  projectId;
}

@Injectable()
export class AnnotationService {
  private sub: Subscription = new Subscription();
  public tokensListSubjects: { [key: string]: BehaviorSubject<string> } = {};
  public subs: { [key: string]: Subscription } = {};
  private currentUserName: string;

  public constructor(
    private coreGraphQLService: CoreGraphQLService,
    private alertService: AlertService,
    private toastr: ToastrService,
    private ongoingService: OngoingOperationsService
  ) {
    this.ongoingService.subject.subscribe(
      (data: { username: string; logout: boolean }) => {
        this.currentUserName = data.username;
        if (!data.logout) {
          this.restoreAnnotationProcess();
        }
      }
    );
  }

  public annotateLicense(
    taskToken: string,
    licenseId: string,
    projectId: string
  ): void {
    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    if (
      !this.tokensListSubjects ||
      !this.tokensListSubjects[`${licenseId}${projectId}`]
    ) {
      this.tokensListSubjects[`${licenseId}${projectId}`] = new BehaviorSubject(
        taskToken
      );
    } else {
      this.tokensListSubjects[`${licenseId}${projectId}`].next(taskToken);
    }
    // in case we are logged in, as subscription only fires on login button
    if (!this.currentUserName && user) {
      this.currentUserName = user.username;
    }
    if (this.subs[`${licenseId}${projectId}`]) {
      this.subs[`${licenseId}${projectId}`].unsubscribe();
    }
    this.subs[`${licenseId}${projectId}`] = timer(0, 4000).subscribe(
      (val: number) => {
        if (this.currentUserName) {
          this.saveAnnotationProcessData([
            {
              currentUserName: this.currentUserName,
              taskToken,
              licenseId,
              projectId,
            },
          ]);
          this.checkAnotationStatus(taskToken, licenseId, projectId);
        } else {
          this.sub.unsubscribe();
        }
      }
    );
  }

  private checkAnotationStatus(
    taskToken: string,
    licenseId: string,
    projectId: string
  ): void {
    this.attributeAssetsByLicenseAsyncResult(taskToken).subscribe(
      (
        result: ApolloQueryResult<{
          attributeAssetsByLicenseAsyncResult: AttributionProcess;
        }>
      ) => {
        const attributionResult: AttributionProcess =
          result.data.attributeAssetsByLicenseAsyncResult;
        if (attributionResult.status === 'COMPLETE') {
          this.resetTaskRunnigData(licenseId, projectId);
          this.showMessageResult(
            attributionResult.result.attributionStatus,
            attributionResult.result.message
          );
        } else if (attributionResult.status === 'ERROR') {
          this.resetTaskRunnigData(licenseId, projectId);
          this.alertService.alertBox(
            attributionResult.statusMessage,
            'License attribution',
            'error'
          );
        }
      }
    );
  }

  private resetTaskRunnigData(licenseId: string, projectId: string): void {
    let annotationData: Array<AnnotationStorageInfo> = JSON.parse(
      localStorage.getItem('annotationProcess')
    );
    annotationData = annotationData.filter(
      (item: AnnotationStorageInfo) => item.licenseId !== licenseId
    );
    localStorage.setItem('annotationProcess', JSON.stringify(annotationData));
    this.subs[`${licenseId}${projectId}`].unsubscribe();
    this.tokensListSubjects[`${licenseId}${projectId}`].next(null);
  }

  private showMessageResult(status: string, comment: string): void {
    if (status !== 'ERROR') {
      this.toastr.success(
        `Attribution is successful: ${comment}`,
        'License attribution'
      );
    } else {
      this.alertService.alertBox(`${comment}`, 'License attribution', 'error');
    }
  }

  private attributeAssetsByLicenseAsyncResult(taskToken): Observable<
    ApolloQueryResult<{
      attributeAssetsByLicenseAsyncResult: AttributionProcess;
    }>
  > {
    return this.coreGraphQLService.coreGQLReq<{
      attributeAssetsByLicenseAsyncResult: AttributionProcess;
    }>(
      gql`
                query {
                attributeAssetsByLicenseAsyncResult(taskToken: "${taskToken}") {
                    statusMessage
                    status
                    result {
                        attributionStatus
                        message
                    }
                  }
                }
              `,
      'no-cache'
    );
  }

  public restoreAnnotationProcess(): void {
    const annotationData: Array<AnnotationStorageInfo> = JSON.parse(
      localStorage.getItem('annotationProcess')
    );
    if (annotationData && annotationData.length > 0) {
      annotationData.forEach((item: AnnotationStorageInfo) => {
        if (item.currentUserName === this.currentUserName) {
          this.annotateLicense(item.taskToken, item.licenseId, item.projectId);
        }
      });
    }
  }

  public saveAnnotationProcessData(
    annotationData: Array<AnnotationStorageInfo>
  ): void {
    const annotationDataFromStrage: Array<AnnotationStorageInfo> = JSON.parse(
      localStorage.getItem('annotationProcess')
    );

    if (
      annotationDataFromStrage &&
      !annotationDataFromStrage.find(
        (annotation) =>
          annotation.licenseId === annotationData[0].licenseId &&
          annotation.projectId === annotationData[0].projectId
      )
    ) {
      annotationDataFromStrage.push(annotationData[0]);
      localStorage.setItem(
        'annotationProcess',
        JSON.stringify(annotationDataFromStrage)
      );
      return;
    }
    localStorage.setItem('annotationProcess', JSON.stringify(annotationData));
  }

  public deleteAnnotationProcessData(): void {
    localStorage.setItem('annotationProcess', '[]');
  }
}
