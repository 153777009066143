import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IgnoredFiles, Level, Type } from '@app/models/ignored-files';
import { AuthenticationService } from '@app/security/services';
import { ScanService } from '@app/services/scan.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-ignore-assets-dialog',
  templateUrl: './ignore-assets-dialog.component.html',
  styleUrls: ['./ignore-assets-dialog.component.scss'],
})
export class IgnoreAssetsDialogComponent implements OnInit {
  public projectId: string;
  public entityId: string;
  public ignoredAssets: any;
  public fromScp: boolean;
  public projects: Array<{ name: string; projectId: string }>;
  public selectedProject: { name: string; projectId: string };
  public ignoreAssetsForm: FormGroup;
  public IgnoredAssetsLevel = Level;
  public IgnoredAssetsType = Type;
  private ignoreAssetsSubscription: Subscription;

  public constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private scanService: ScanService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.initIgnoreAssetsForm(this.ignoredAssets);
  }

  public close(): void {
    this.activeModal.close();
  }

  /** Add a setting to ignore assets */
  onAddIgnoreAssetsSetting() {
    if (this.ignoreAssetsSubscription) {
      this.ignoreAssetsSubscription.unsubscribe();
    }

    const settingGroups = this.ignoreAssetsForm.get('settings') as FormArray;
    const addSettingGroup = settingGroups.at(0) as FormGroup;

    const ignoredAsset = new IgnoredFiles();

    const { pattern, level, type, project } = addSettingGroup.value;

    const objectID = this.getIgnoredAssetObjectID(level, project);

    ignoredAsset.objectId = objectID;
    ignoredAsset.pattern = pattern;
    ignoredAsset.level = level;
    ignoredAsset.type = type;

    this.ignoreAssetsSubscription = this.scanService
      .saveIgnoredFiles(ignoredAsset)
      .subscribe(
        () => {
          const value = { objectID, pattern, level, type };

          const settingGroup = this.fb.group({
            ...value,
            previousValue: { ...value },
          });

          this.surppressIgnoreAssetDuplicate(settingGroup);

          settingGroups.insert(1, settingGroup);

          addSettingGroup.reset();
          this.toastr.success('The asset has successfully been added');
        },
        (error) => {
          this.toastr.error('The asset has not been added');
        }
      );
  }

  /** Reset a setting to ignore assets */
  onResetIgnoreAssetsSetting() {
    const settingGroups = this.ignoreAssetsForm.get('settings') as FormArray;
    const addSettingGroup = settingGroups.at(0);

    addSettingGroup.reset();
  }

  /**
   * Edit an ignore assets setting
   *
   * @param index setting group index in the form
   */
  onEditIgnoreAssetsSetting(index: number) {
    const settingGroups = this.ignoreAssetsForm.get('settings') as FormArray;
    const editSettingGroup = settingGroups.at(index) as FormGroup;

    const previousIgnoredAsset = new IgnoredFiles();

    const { pattern, level, type, previousValue } = editSettingGroup.value;

    previousIgnoredAsset.objectId = previousValue.objectID;
    previousIgnoredAsset.pattern = previousValue.pattern;
    previousIgnoredAsset.level = previousValue.level;
    previousIgnoredAsset.type = previousValue.type;

    const ignoredAsset = new IgnoredFiles();

    const objectID = this.getIgnoredAssetObjectID(
      level,
      this.fromScp ? previousIgnoredAsset.objectId : null
    );

    ignoredAsset.objectId = objectID;
    ignoredAsset.pattern = pattern;
    ignoredAsset.level = level;
    ignoredAsset.type = type;

    this.scanService
      .removeIgnoredFiles(previousIgnoredAsset)
      .pipe(mergeMap(() => this.scanService.saveIgnoredFiles(ignoredAsset)))
      .subscribe(
        () => {
          editSettingGroup.value.previousValue = {
            objectID,
            pattern,
            level,
            type,
          };

          this.surppressIgnoreAssetDuplicate(editSettingGroup);

          editSettingGroup.markAsPristine();

          if (editSettingGroup.value.level !== 'PROJECT') {
            editSettingGroup.controls.project.disable();
          }

          this.toastr.success('The asset has successfully been edited');
        },
        (error) => {
          this.toastr.error('The asset has not been edited');
        }
      );
  }

  /**
   * Remove an ignore assets setting
   *
   * @param index setting index in the form
   */
  onRemoveIgnoreAssetsSetting(index: number) {
    const settingGroups = this.ignoreAssetsForm.get('settings') as FormArray;
    const removeSettingGroup = settingGroups.at(index);

    const previousIgnoredAsset = new IgnoredFiles();

    const { objectID, pattern, level, type } =
      removeSettingGroup.value.previousValue;

    previousIgnoredAsset.objectId = objectID;
    previousIgnoredAsset.pattern = pattern;
    previousIgnoredAsset.level = level;
    previousIgnoredAsset.type = type;

    this.scanService.removeIgnoredFiles(previousIgnoredAsset).subscribe(
      () => {
        settingGroups.removeAt(index);
        this.toastr.success('The asset has successfully been removed');
      },
      (error) => {
        this.toastr.error('The asset has not been removed');
      }
    );
  }

  private getIgnoredAssetObjectID(level: Level, objId: string): string {
    switch (level) {
      case Level.PROJECT:
        if (this.fromScp) {
          return objId;
        }
        return this.projectId;

      case Level.ENTITY:
        return this.entityId;

      case Level.ORGANIZATION:
        return this.authenticationService.currentUser.orgId;

      case Level.SCP_PROJECT:
        return this.entityId;

      default:
        return '';
    }
  }

  /**
   * Remove ignore asset group duplicate
   *
   * @param settingGroup ignore asset group
   */
  private surppressIgnoreAssetDuplicate(settingGroup: FormGroup) {
    const settingGroups = this.ignoreAssetsForm.get('settings') as FormArray;

    // Remove duplicating group
    const duplicateGroupIndex = settingGroups.controls.findIndex((group) => {
      // Skip the add group
      if (group === settingGroup) {
        return false;
      }

      const { objectID, pattern, level, type } = settingGroup.value;

      // Match all fields
      if (
        group.value.objectID === objectID &&
        group.value.pattern === pattern &&
        group.value.level === level &&
        group.value.type === type
      ) {
        return true;
      }
    });

    if (duplicateGroupIndex !== -1) {
      settingGroups.removeAt(duplicateGroupIndex);
    }
  }

  /** Initialize Ignore assets form */
  private initIgnoreAssetsForm(ignoredAssets: IgnoredFiles[]) {
    this.ignoreAssetsForm = this.fb.group({
      settings: this.fb.array([
        this.fb.group({
          objectID: undefined,
          pattern: undefined,
          level: undefined,
          type: undefined,
          project: undefined,
        }),
      ]),
    });

    // Populate Ignore assets form with ignored assets
    const settingGroups = this.ignoreAssetsForm.get('settings') as FormArray;

    ignoredAssets.forEach(
      ({ objectId, pattern, level, type }: IgnoredFiles) => {
        const value = {
          objectID: objectId,
          pattern,
          level,
          type,
        };
        value['project'] = this.projects?.find(
          (proj: { name: string; projectId: string }) =>
            proj.projectId === value.objectID
        )?.projectId;
        const settingGroup = this.fb.group({
          ...value,
          previousValue: { ...value },
        });
        if (level !== 'PROJECT') {
          settingGroup.controls.project.disable();
        }
        settingGroups.insert(1, settingGroup);
      }
    );
  }

  disableProject(level: Level, i: number): void {
    if (level !== 'PROJECT') {
      this.ignoreAssetsForm
        .get('settings')
        ['controls'][i].controls.project.reset();
      this.ignoreAssetsForm
        .get('settings')
        ['controls'][i].controls.project.disable();
    } else {
      this.ignoreAssetsForm
        .get('settings')
        ['controls'][i].controls.project.enable();
    }
  }
}
