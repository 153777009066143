import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { PatchedInfoSimplified } from '@app/threat-center/shared/models/types';
import { FixService } from '@app/services/fix.service';
import { finalize, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FixResult } from '@app/models';
import { FixComponentResultDialogComponent } from '@app/shared/components/fix-component-result-dialog/fix-component-result-dialog.component'; // eslint-disable-line

@Component({
  selector: 'app-fix-component-dialog',
  templateUrl: './fix-component-dialog.component.html',
  styleUrls: ['./fix-component-dialog.component.scss'],
})
export class FixComponentDialogComponent implements OnInit, OnDestroy {
  public scanId: string;
  public newVersion: string;
  public oldVersion: string;
  public componentId: string;

  public patchedVersions: PatchedInfoSimplified;
  public loading: boolean = true;

  public subscription: Subscription;

  public constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fixService: FixService
  ) {}

  public ngOnInit(): void {
    this.subscription = this.fixService
      .getPatchedVersion(this.componentId)
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe((patchedVersion: PatchedInfoSimplified) => {
        this.patchedVersions = patchedVersion;
        if (
          patchedVersion.nextPatchedVersion ===
          patchedVersion.latestPatchedVersion
        ) {
          this.newVersion = patchedVersion.latestPatchedVersion;
        }
      });
  }

  /**
   * unsubscribe from the observable to avoid memory leaks
   */
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public fixVersion(): void {
    this.subscription.add(
      this.fixService
        .fixComponentVersion(
          this.scanId,
          this.componentId,
          this.oldVersion,
          this.newVersion
        )
        .subscribe(
          (results: Array<FixResult>) => {
            const modalRef: NgbModalRef = this.modalService.open(
              FixComponentResultDialogComponent,
              {
                keyboard: false,
              }
            );
            modalRef.componentInstance.fixResults = results;
          },
          (error: Error) => {
            console.error('error: ' + error.message);
          }
        )
    );
  }

  public closeBtn(): void {
    this.activeModal.close();
  }
}
