import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import gql from 'graphql-tag';
import { Observable, Subject } from 'rxjs';
import { CoreGraphQLService } from './core';
import { Entity } from '@app/models';

export type EntitySubType = 'AI_CERTIFY';

@Injectable({
  providedIn: 'root',
})
export class SupplyChainProjectService {
  public timerIsActive: boolean;
  public constructor(private coreGraphQLService: CoreGraphQLService) {}

  public supplyChainProject(entityId: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query SupplyChainProject($entityId: UUID) {
          supplyChainProject(entityId: $entityId) {
            entityId
            entitySubType
            name
            parentEntityId
            orgId
            entityMetricsSummaryGroup {
              entityMetricsSummaries {
                measureDate
                vulnerabilityMetrics {
                  critical
                  high
                  medium
                  low
                  info
                }
                licenseMetrics {
                  copyleftStrong
                  copyleftWeak
                  copyleftPartial
                  copyleftLimited
                  copyleft
                  custom
                  dual
                  permissive
                }
                supplyChainMetrics {
                  risk
                  quality
                }
                assetMetrics {
                  embedded
                  openSource
                  unique
                }
                componentCountMetrics {
                  totalCount
                  riskyLicenses
                  vulnerableComponents
                }
              }
            }

            entityMetricsGroup {
              projectCount
              entityMetrics {
                measureDate
                vulnerabilityMetrics {
                  severityMetrics
                }
                assetMetrics {
                  assetCompositionMetrics
                }
                componentMetrics {
                  vulnerabilityMetrics
                  licenseCategoryMetrics
                  licenseFamilyMetrics
                  licenseNameMetrics
                }
                componentCountMetrics {
                  totalCount
                }
                licenseMetrics {
                  licenseCategoryMetrics
                  licenseFamilyMetrics
                  licenseNameMetrics
                }
                supplyChainMetrics {
                  supplyChainMetrics
                }
              }
            }

            projects {
              edges {
                node {
                  orgId
                  entityId
                  projectId
                  parentProjectId
                  name
                  created
                  latestScan {
                    orgId
                    scanId
                    projectId
                    entityId
                    scanRepoId
                    repositoryId
                    branch
                    commit
                    tag
                    vulnerabilityCount
                    licenseCount
                    scanMetricsSummary {
                      vulnerabilityMetrics {
                        critical
                        high
                        info
                        low
                        medium
                      }
                      licenseMetrics {
                        copyleft
                        copyleftLimited
                        copyleftPartial
                        copyleftStrong
                        copyleftWeak
                        custom
                        dual
                        permissive
                        proprietary
                        proprietaryFree
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      'no-cache',
      { entityId }
    );
  }

  public supplyChainProjectList(
    parentEntityId: string,
    aiCertify?: EntitySubType
  ): Observable<ApolloQueryResult<{ supplyChainProjectList: Array<Entity> }>> {
    const params: string = aiCertify ? `, subType: ${aiCertify}` : '';
    return this.coreGraphQLService.coreGQLReq<{
      supplyChainProjectList: Array<Entity>;
    }>(
      gql`
        query SupplyChainProjectList($parentEntityId: UUID) {
          supplyChainProjectList(parentEntityId: $parentEntityId ${params}) {
            name
            entityId
            entitySubType
            scpProjectMetrics
            latestScanByScpProject {
              created
            }
          }
        }
      `,
      'no-cache',
      { parentEntityId }
    );
  }

  public createSupplyChainProject(
    name: string,
    parentEntityId: string,
    aiCertify?: EntitySubType
  ): Observable<FetchResult<{ createSupplyChainProject: Entity }>> {
    const params: string = aiCertify ? `, subType: ${aiCertify}` : '';
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation {
          createSupplyChainProject(
            name: "${name}",
            parentEntityId: "${parentEntityId}",
            ${params}
          ) {
            entityId,
            name,
            orgId
          }
        }
      `
    );
  }

  public getScpProjects(scpProjectName: string) {
    return this.coreGraphQLService.coreGQLReq<{
      scpProjectsGroupedByEntities: any;
    }>(
      gql`
        query {
          scpProjectsGroupedByEntities(scpProjectName: "${scpProjectName}") {
            entity {
              entityId
              name
            }
            scpProjects {
              orgId
              entityId
              scpProjectName
              scpProjectType
              scpProjectId
            }
          }
        }
      `,
      'no-cache'
    );
  }

  public moveModule(
    moduleId: string,
    oldScpProjectId: string,
    newScpProjectId: string
  ) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation MoveModule(
          $moduleId: UUID!
          $oldScpProjectId: UUID!
          $newScpProjectId: UUID!
        ) {
          moveModule(
            moduleId: $moduleId
            oldScpProjectId: $oldScpProjectId
            newScpProjectId: $newScpProjectId
          )
        }
      `,
      { moduleId, oldScpProjectId, newScpProjectId }
    );
  }
}
