<div class="container">
  <!-- <ng-container *ngTemplateOutlet="mainHeader"></ng-container> -->
</div>
<!-- <ng-template #mainHeader>
  <div class="m-header">
    <a
      href="javascript:"
      class="mobile-menu"
      id="mobile-collapse"
      (click)="navCollapse()"
      ><span></span
    ></a>
    <a [routerLink]="['/security/entity']" class="b-brand">
      <img
        id="main-logo"
        src="assets/images/website_logo_white-letters-transparent_background.png"
        alt=""
        class="logo"
        *ngIf="
          this.nextConfig.navBrandColor !== 'brand-default' ||
          this.nextConfig.headerBackColor !== 'header-default'
        "
      />
      <img
        src="assets/images/logo-dark.png"
        alt=""
        class="logo"
        *ngIf="
          this.nextConfig.navBrandColor === 'brand-default' &&
          this.nextConfig.headerBackColor === 'header-default'
        "
      />
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb" />
    </a>
    <div class="threat-center">Threat Center</div>
    <a
      class="mob-toggler"
      [ngClass]="{ on: this.menuClass }"
      href="javascript:"
      (click)="toggleMobOption()"
      ><i class="feather icon-more-vertical"></i
    ></a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-left class="mr-auto"></app-nav-left>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template> -->
