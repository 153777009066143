import { GraphQLPage } from '@app/models/paging';

export class LoadAssetCards {
  static type = '[AssetCards] LoadAssetCards';

  constructor(
    public entityId: string,
    public scanId: string,
    public assetPath: string,
    public graphQLPage?: GraphQLPage
  ) {}
}

export class LoadNextPageAssetCards {
  static type = '[AssetCards] LoadNextPageAssetCards';
}

export class ReviewAsset {
  static type = '[AssetCards] ReviewAsset';

  constructor(
    public actionType: 'approve' | 'ignore' | 'reject' | 'revert',
    public repositoryId: string
  ) {}
}

export class ReviewComponent {
  static type = '[AssetCards] ReviewComponent';

  constructor(public actionType: 'approve' | 'ignore' | 'reject' | 'revert') {}
}

export class SetFilterStringForCards {
  static type = '[AssetCards] SetFilterStringForCard';

  constructor(public filterString: string) {}
}

export class ClearCards {
  static type = '[AssetCards] ClearCards';

  constructor() {}
}

export class OverrideSourceRepoForCards {
  static type = '[AssetCards] OverrideSourceRepoForCards';

  constructor(
    public sourceRepositoryId: string,
    public targetRepositoryId: string,
    public overridingList: {
      [key: string]: boolean;
    }
  ) {}
}

export class UpdateCardData {
  static type = '[AssetCards] UpdateCardData';

  constructor(public asset: any) {}
}
