<div class="modal-header" *ngIf="selectedAsset">
  <h5 class="modal-title">
    {{
      !!selectedAsset.attributionStatus &&
      selectedAsset.attributionStatus === 'COMPLETE'
        ? 'Licenses Attributed'
        : 'Select the licenses to be attributed in the header of this source file'
    }}
  </h5>
  <button
    type="button"
    class="close basic-close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="selectedAsset">
  <div
    *ngIf="selectedAsset.sourceAssetAttribution"
    class="row"
    style="margin-bottom: 10px"
  >
    <div class="col-md-8">
      Licenses attributed by
      {{ selectedAsset.sourceAssetAttribution?.attributedBy }}
      {{
        selectedAsset.sourceAssetAttribution?.attributedDate
          | date : 'MMMM d, y'
      }}
      with status:
      {{ selectedAsset.sourceAssetAttribution?.attributionStatus }}
    </div>
  </div>
  <div class="select-all-wrapper">
    <span>Select all</span>
    <input
      class="select-all-checkbox"
      name="selectAll"
      [(ngModel)]="allSelected"
      type="checkbox"
      (change)="selectAllLicenses($event)"
    />
  </div>
  <perfect-scrollbar [style.max-height]="'550px'" class="overflowshadowTop">
    <p-table
      #dt
      [value]="licenses"
      [paginator]="false"
      [rows]="50"
      tableStyleClass="table table-striped table-bordered table-hover dataTable no-footer table-width"
      [autoLayout]="true"
      [filterDelay]="0"
      [globalFilterFields]="['name']"
      [scrollable]="false"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th>License ID</th>
          <th>License Name</th>
          <th>License Category</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-matchLicense>
        <tr>
          <td class="align-middle">
            <input
              name="assetMatch"
              type="checkbox"
              [id]="matchLicense.licenseId"
              [(ngModel)]="selectedLicenses[matchLicense.licenseId]"
              (ngModelChange)="checkIfAllSelected()"
              *ngIf="selectedAsset.attributionStatus !== 'COMPLETE'"
            />
          </td>
          <td>{{ matchLicense.licenseId }}</td>
          <td>{{ matchLicense.name }}</td>
          <td>
            <span
              class="p-l-5 badge m-r-5 badge-light-warning"
              [ngClass]="'badge-light-' + matchLicense.category"
            >
              {{ matchLicense.category?.replace('_', ' ') }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </perfect-scrollbar>
  <label class="font-weight-bolder pt-2 mt-4">Attribution Comment</label>
  <textarea
    class="form-control mt-2"
    name="attributionComment"
    [(ngModel)]="attributionComment"
    placeholder="Attribution Comment"
  ></textarea>
</div>
<div class="modal-footer">
  <button
    *ngIf="selectedAsset?.attributionStatus !== 'COMPLETE'"
    type="button"
    class="btn btn-primary mr-2"
    data-dismiss="modal"
    (click)="attributeAsset(false)"
    [disabled]="!hasSelectedLicenses()"
  >
    Attribute
  </button>
  <button
    *ngIf="selectedAsset?.attributionStatus !== 'COMPLETE'"
    type="button"
    class="btn btn-danger mr-2"
    data-dismiss="modal"
    (click)="attributeAsset(true)"
    [disabled]="!hasSelectedLicenses()"
  >
    Ignore
  </button>
  <button
    type="button"
    class="btn btn-secondary"
    data-dismiss="modal"
    (click)="close()"
  >
    Close
  </button>
</div>
