import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode';

import { LocalService } from '@app/services/core/local.service';

import { Repository, User } from '../../models';

import { Account } from '@app/threat-center/shared/models/types';

import { environment } from '../../../environments/environment';
import { OngoingOperationsService } from '@app/services/ongoing-operations.service';
import { AuthorizedClients } from '@app/models/client';
import { Store } from '@ngxs/store';
import { ClearCards } from '@app/compliance-dashboard-new/results-and-review/results-and-review-store/asset-cards-store/asset-cards.action';
import { ClearList } from '@app/compliance-dashboard-new/results-and-review/results-and-review-store/asset-list-store/asset-list.action';
import { ResetFilters } from '@app/compliance-dashboard-new/results-and-review/results-and-review-store/results-and-review.action';
import { ClearEntity } from '@app/compliance-dashboard-new/store/entity/ngxs.action';
import { ClearEntityData } from '@app/threat-center/dashboard/entity/store/ngxs.action';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
  public loginLoading: BehaviorSubject<string> = new BehaviorSubject('');
  public approvedUser: Subject<boolean> = new Subject();
  public currentUserSubject: BehaviorSubject<User>;
  public currentAuthorizedClientsSubject: BehaviorSubject<
    Array<AuthorizedClients>
  >;
  private websocketClient = null;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private localService: LocalService,
    private cookieService: CookieService,
    private ongoingOperations: OngoingOperationsService,
    private store: Store,
    private router: Router
  ) {
    // let user = this.getFromStorageBasedEnv('currentUser');
    const user = this.getFromSessionStorageBasedEnv('currentUser');
    const authorizedClients =
      this.getFromSessionStorageBasedEnv('authorizedClients');
    this.currentAuthorizedClientsSubject = new BehaviorSubject<
      Array<AuthorizedClients>
    >(authorizedClients);
    this.currentUserSubject = new BehaviorSubject<User>(user);
  }

  public get currentUser(): User {
    // note: I don't think the loading is working in constructor
    if (
      (!this.currentUserSubject || !this.currentUserSubject.value) &&
      this.getFromSessionStorageBasedEnv('currentUser')
    ) {
      console.log('Current user subject is null. Loading from localStorage');
      this.currentUserSubject = new BehaviorSubject<User>(
        this.getFromSessionStorageBasedEnv('currentUser')
      );
    }
    return this.currentUserSubject.value;
  }

  public get authorizedClients(): Array<AuthorizedClients> {
    if (
      (!this.currentAuthorizedClientsSubject ||
        !this.currentAuthorizedClientsSubject.value) &&
      this.getFromSessionStorageBasedEnv('authorizedClients')
    ) {
      console.log(
        'Current authorizedClients subject is null. Loading from localStorage'
      );
      this.currentAuthorizedClientsSubject = new BehaviorSubject<
        Array<AuthorizedClients>
      >(this.getFromSessionStorageBasedEnv('authorizedClients'));
    }
    return this.currentAuthorizedClientsSubject.value;
  }

  public get currentUserName(): string {
    const { fname, lname, username } = this.currentUser || {};
    return fname ? `${fname} ${lname}` : username;
  }

  login(username: string, password: string) {
    const url = environment.apiUrl + '/rest/auth/authenticate';
    const body = {
      username,
      password,
    };
    this.localService.clearToken();
    this.cookieService.delete('jwt', '/');
    return this.http.post<any>(url, body).pipe(
      map(
        (response) => {
          // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
          this.setCookieBasedEnv('jwt', response.jwt);
          const user = response.user;
          const authorizedClients = response.authorizedClients;
          this.setCurrentUser(user);
          this.setAuthorizedClients(authorizedClients);

          this.setLastSuccessfulLogin(true);
          this.joinAccountsIfNeeded();
          // restore ongoing annotation process if there is, timeout as the auth process
          // isn't up to speed with this
          setTimeout(() => {
            this.ongoingOperations.subject.next({
              username: this.currentUser.username,
              logout: false,
            });
          }, 4000);
          return user;
        },
        (err) => {
          console.error('AUTH SERVICE ERROR:', err);
        }
      )
    );
  }

  async logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    const annotations = localStorage.getItem('annotationProcess');
    const scanningProject = localStorage.getItem('SCANNING_PROJECTS');
    const loginChoiceData = localStorage.getItem('loginChoiceData');

    console.log('Logout called');
    this.localService.clearToken();

    this.store.dispatch(new ClearCards());
    this.store.dispatch(new ClearList());
    this.store.dispatch(new ResetFilters(true));
    this.store.dispatch(new ClearEntity());
    this.store.dispatch(new ClearEntityData());

    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.delete('jwt', '/');

    if (annotations) {
      localStorage.setItem('annotationProcess', annotations);
    }
    if (scanningProject) {
      localStorage.setItem('SCANNING_PROJECTS', scanningProject);
    }
    if (loginChoiceData) {
      localStorage.setItem('loginChoiceData', loginChoiceData);
    }

    this.modalService.dismissAll();
    this.currentUserSubject.next(null);
    this.ongoingOperations.subject.next({
      username: null,
      logout: true,
    });
    this.closeWebSocket();

    await this.router.navigate(['/login']);
  }

  public isTokenExpired(token?: string): boolean {
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  setCurrentUser(user: User) {
    if (environment.production && !environment.staging) {
      this.localService.setLocalStorage('currentUser', user);
    } else {
      localStorage.setItem('currentUser', JSON.stringify(user));
    }
    this.currentUserSubject.next(user);
  }

  setAuthorizedClients(authorizedClients: Array<AuthorizedClients>): void {
    if (environment.production && !environment.staging) {
      this.localService.setLocalStorage('authorizedClients', authorizedClients);
    } else {
      localStorage.setItem(
        'authorizedClients',
        JSON.stringify(authorizedClients)
      );
    }
    this.currentAuthorizedClientsSubject.next(authorizedClients);
  }

  getFromStorageBasedEnv(key: string) {
    let user = null;
    if (environment.production && !environment.staging) {
      user = this.localService.getLocalStorage(key);
    } else {
      if (localStorage.getItem(key)) {
        user = JSON.parse(localStorage.getItem(key));
      }
    }
    return user;
  }

  setInSessionStorageBasedEnv(key: string, data: any) {
    if (environment.production && !environment.staging) {
      this.localService.setSessionStorage(key, data);
    } else {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }

  getFromSessionStorageBasedEnv(key: string) {
    let user = null;
    if (environment.production && !environment.staging) {
      user = this.localService.getSessionStorage(key);
    } else {
      if (
        sessionStorage.getItem(key) &&
        sessionStorage.getItem(key) !== 'undefined'
      ) {
        user = JSON.parse(sessionStorage.getItem(key));
      }
    }
    return user;
  }

  setCookieBasedEnv(key: string, data: any) {
    if (environment.production && !environment.staging) {
      this.localService.setCookieStorage(key, data);
    } else {
      this.cookieService.set(key, data, null, '/');
    }
  }

  getFromCookie(key: string) {
    if (environment.production && !environment.staging) {
      return this.localService.getCookieStorage(key) || null;
    } else {
      return this.cookieService.get(key) || null;
    }
  }

  // set websocket client
  setWebSocketClient(wsClient) {
    this.websocketClient = wsClient;
  }

  // close websocket
  closeWebSocket() {
    if (!!this.websocketClient) {
      this.websocketClient.unsubscribeAll();
      this.websocketClient.close(true);
      this.websocketClient = null;
    }
  }

  // get available github repository type from cookie
  getGitHubRepoType() {
    if (this.cookieService.check('gh_rt')) {
      return this.cookieService.get('gh_rt');
    } else {
      return undefined;
    }
  }

  // save github repository type in cookie
  setGitHubRepoType(value: string) {
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 181); // valid 181 days
    this.cookieService.set('gh_rt', value, {
      path: '/',
      expires: expiredDate,
      sameSite: 'Lax',
    });
  }

  setLastSuccessfulLogin(passwordFlag?: boolean) {
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 181); // valid 181 days
    const user: User = this.currentUser;
    if (this.authorizedClients) {
      const userLoginInfo = {
        username: user.username,
        password: passwordFlag
          ? {
              type: 'PASSWORD',
              accountName: user.username,
            }
          : null,
        github: null,
        gitlab: null,
        bitbucket: null,
        google: null,
      } as LoginData;
      this.authorizedClients.forEach((client: AuthorizedClients) => {
        userLoginInfo[client.clientRegistration.registrationId] = {
          accountName: client.user.accountName,
          type: client.clientRegistration.providerDetails.providerType?.toLowerCase(),
          accessToken: client.accessToken?.tokenValue,
          scopes: client.clientRegistration.scopes,
        };
      });

      this.cookieService.set('last_login_data', JSON.stringify(userLoginInfo), {
        path: '/',
        expires: expiredDate,
        sameSite: 'Lax',
      });
    }
  }

  getLastSuccessfulLogin(): LoginData {
    if (this.cookieService.check('last_login_data')) {
      return JSON.parse(this.cookieService.get('last_login_data'));
    } else {
      return undefined;
    }
  }

  joinAccountsIfNeeded() {
    const accountToJoin = this.getFromSessionStorageBasedEnv(`accountToJoin`);
    if (accountToJoin != null && accountToJoin !== undefined) {
      const jwt = this.getFromCookie(`jwt`);
      const url =
        environment.apiUrl +
        '/rest/auth/code/' +
        accountToJoin +
        '?joinUser=true&jwt=' +
        jwt;
      this.removeJoinAccount();

      window.location.href = url;
    }
  }

  setJoinAccount(loginType: string) {
    this.setInSessionStorageBasedEnv('accountToJoin', loginType);
  }

  removeJoinAccount() {
    if (environment.production && !environment.staging) {
      this.localService.setSessionStorage('accountToJoin', undefined);
    } else {
      sessionStorage.removeItem('accountToJoin');
    }
  }

  getUserRepoAccountAccessToken(
    authorizedClients: Array<AuthorizedClients>,
    scanRepository: Repository
  ): string {
    let accessToken;
    authorizedClients.forEach((client: AuthorizedClients) => {
      if (
        client.clientRegistration.providerDetails.providerType ===
        scanRepository.repositoryEndpointType
      ) {
        accessToken = client.accessToken.tokenValue;
      }
    });
    return accessToken;
  }
}

export enum LoginType {
  GITHUB = 'github',
  GITLAB = 'gitlab',
  BITBUCKET = 'bitbucket',
  GOOGLE = 'google',
  PASSWORD = 'password',
  ONPREM = 'on_prem_login',
}

export interface LoginData {
  username: string;
  password: Account;
  github: Account;
  gitlab: Account;
  bitbucket: Account;
  google: Account;
}
