import { Injectable } from '@angular/core';
import { LoginType } from '@app/security/services';

/**
 * Service for working with localStorage. All item in localStorage should be stringified by JSON.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private loginChoiceData: string = 'loginChoiceData';
  private activeComplianceTab: string = 'activeComplianceTab';
  private complianceTreeState: string = 'complianceTreeState';
  private shrinkTree: string = 'shrinkTree';
  private aiDashboardView: string = 'aiDashboardView';
  private showGraphs: string = 'showGraphs';
  private overridingReposList: string = 'overridingReposList';
  private block1Width: string = 'block1Width';
  private block2Width: string = 'block2Width';
  private movingModule: string = 'movingModule';

  /**
   *
   *
   * Get item in local storage by key and parse it
   *
   *
   * @param key
   *
   * @returns
   *
   */
  public get(key: string): any {
    //tslint:disable-line
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Set item by key. Item will be stringified by JSON
   *
   * @param key
   * @param data
   */
  public save(key: string, data: any): void {
    //tslint:disable-line
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   *  Set item by key. Data won't be stringified,
   *
   * @param key
   * @param data
   */
  public saveString(key: string, data: any): void {
    localStorage.setItem(key, data);
  }

  public getString(key: string): string {
    return localStorage.getItem(key);
  }
  /**
   * Remove item by key
   *
   * @param key
   */
  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public setLoginChoiceData(value: {
    loginType: string;
    remember: boolean;
    createNewUser: boolean;
  }): void {
    this.save(this.loginChoiceData, value);
  }

  public getLoginChoiceData(): {
    loginType: string;
    remember: boolean;
    createNewUser: boolean;
  } {
    return this.get(this.loginChoiceData);
  }

  public setActiveComplianceTab(value: string): void {
    this.save(this.activeComplianceTab, value);
  }

  public getActiveComplianceTab(): string {
    return this.get(this.activeComplianceTab);
  }

  public setComplianceTreeState(value: string): void {
    this.save(this.complianceTreeState, value);
  }

  public getComplianceTreeState(): string {
    return this.get(this.complianceTreeState);
  }

  public getShrinkTree(): boolean {
    return this.get(this.shrinkTree);
  }

  public setShrinkTree(shrink: boolean): void {
    this.save(this.shrinkTree, shrink);
  }

  public deleteShrinkTree(): void {
    this.remove(this.shrinkTree);
  }

  public getAiDashboardView(): number {
    return this.get(this.aiDashboardView);
  }

  public setAiDashboardView(view: number): void {
    this.save(this.aiDashboardView, view);
  }

  public getShowGraphs(): boolean {
    return this.get(this.showGraphs);
  }

  public setShowGraphs(show: boolean): void {
    this.save(this.showGraphs, show);
  }

  public getOverridingReposList(): { [key: string]: boolean } {
    return this.get(this.overridingReposList);
  }

  public setOverridingReposList(list: { [key: string]: boolean }): void {
    this.save(this.overridingReposList, list);
  }

  public getBlock1Width(): number {
    return this.get(this.block1Width);
  }

  public setBlock1Width(width: number): void {
    this.save(this.block1Width, width);
  }

  public deleteBlock1Width(): void {
    this.remove(this.block1Width);
  }

  public getBlock2Width(): number {
    return this.get(this.block2Width);
  }

  public setBlock2Width(width: number): void {
    this.save(this.block2Width, width);
  }

  public deleteBlock2Width(): void {
    this.remove(this.block2Width);
  }

  public getMovingModule(): string {
    return this.get(this.movingModule);
  }

  public setMovingModule(movingModule: string): void {
    this.save(this.movingModule, movingModule);
  }

  public deleteMovingModule(): void {
    this.remove(this.movingModule);
  }
}
