export class LoadComponentDetails {
  static type = '[ComponentDetails] LoadComponentDetails';

  constructor(
    public componentId: string,
    public scanId: string,
    public projectId: string,
    public isComposite: boolean = false,
    public defaultPageSize: number
  ) {}
}

export class LoadPatchedVersions {
  static type = '[ComponentDetails] LoadPatchedVersions';

  constructor(public componentId: string) {}
}

export class LoadNextComponentVersions {
  static type = '[ComponentDetails] LoadNextComponentVersions';
}

export class FixVersionsDialog {
  static type = '[ComponentDetails] FixVersionsDialog';
  constructor(public versionToFix: string) {}
}
