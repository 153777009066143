<div class="metrics-chip">
  <div
    class="metrics-chip-class"
    [class]="color"
    [ngbTooltip]="title | metricsTitleConverter : review"
  >
    {{ title }}
  </div>
  <div class="metrics-chip-number">{{ value }}</div>
</div>
