import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLimitNumber]',
})
export class LimitNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;

    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, '');

    // Limit the value to a maximum of 99
    if (numericValue && parseInt(numericValue, 10) > 99) {
      input.value = '99';
    } else if (numericValue && parseInt(numericValue, 10) < 0) {
      input.value = '0';
    } else {
      input.value = numericValue;
    }

    // Dispatch an input event to ensure Angular detects the change
    if (input.value !== value) {
      input.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }
}
