<p-table
  *ngIf="scanAssetsTree"
  #dt
  [columns]="columns"
  [value]="tableData"
  [paginator]="false"
  [rows]="50"
  tableStyleClass="table table-striped table-bordered table-hover dataTable no-footer table-width"
  [autoLayout]="true"
  [filterDelay]="0"
  [globalFilterFields]="['name']"
  sortField="embedded"
  [sortOrder]="-1"
  [loading]="loading"
  [loadingIcon]="'fa fa-spinner'"
  dataKey="node.scanAssetId"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Matches</th>
      <th>Status</th>
      <th>Match Origin</th>
      <th>Version(s)</th>
      <th>License(s)</th>
      <th>Match Score</th>
      <th>Match Type</th>
    </tr>
    <tr>
      <th *ngFor="let col of columns">
        <input
          id="nameSearch"
          class="form-control"
          name="search-name"
          [value]="getColumnFilterValue(col)"
          [placeholder]="col"
          *ngIf="col === 'Name'"
          (input)="onNameChange($event)"
        />

        <select
          id="embededSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Matches'"
          (change)="onFilterColumn(col, $event, 'embededSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option
            value="over0"
            [label]="MESSAGES.ASSET.FILTER.EMBEDDED_AND_OPENSOURCE"
          ></option>
          <option value="0-10">0-10%</option>
          <option value="11-30">11-30%</option>
          <option value="31-50">31-50%</option>
          <option value="51-70">51-70%</option>
          <option value="71-100">71-100%</option>
        </select>

        <select
          id="statusSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Status'"
          (change)="onFilterColumn(col, $event, 'statusSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="IGNORED_SIZE_SMALL">IGNORED_SIZE_SMALL</option>
          <option value="IGNORED_SIZE_LARGE">IGNORED_SIZE_LARGE</option>
          <option value="IGNORED_SIZE_EMPTY">IGNORED_SIZE_EMPTY</option>
          <option value="IGNORED_TYPE_UNSUPPORTED">
            IGNORED_TYPE_UNSUPPORTED
          </option>
          <option value="IGNORED">IGNORED</option>
          <option value="ACCEPTED">ACCEPTED</option>
        </select>

        <!-- <input
          id="originSearch"
          class="form-control"
          name="search-name"
          [value]="getColumnFilterValue(col)"
          [placeholder]="col"
          *ngIf="col === 'Match Origin'"
          (input)="onOriginChange($event)"
        /> -->

        <select
          id="licenseSearch"
          class="form-control custom-select"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Licenses' && !fromLicenseDimension"
          (change)="onFilterColumn(col, $event, 'licenseSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="UNDEFINED">Undefined</option>
          <option value="PROPRIETARY">Proprietary</option>
          <option value="PUBLIC_DOMAIN">Public Domain</option>
          <option value="PERMISSIVE">Permissive</option>
          <option value="COPYLEFT_WEAK">Copyleft Weak</option>
          <option value="COPYLEFT_STRONG">Copyleft Strong</option>
          <option value="COPYLEFT_PARTIAL">Copyleft Partial</option>
          <option value="COPYLEFT_LIMITED">Copyleft Limited</option>
          <option value="COPYLEFT">Copyleft</option>
        </select>

        <select
          id="matchScoreSearch"
          class="form-control"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Match Score'"
          (change)="onFilterColumn(col, $event, 'matchScoreSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="HIGH">HIGH</option>
          <option value="MEDIUM">MEDIUM</option>
          <option value="LOW">LOW</option>
        </select>

        <select
          id="matchTypeSearch"
          class="form-control"
          [value]="getColumnFilterValue(col)"
          *ngIf="col === 'Match Type'"
          (change)="onFilterColumn(col, $event, 'matchTypeSearch')"
        >
          <option value="ALL" selected>- All -</option>
          <option value="UNIQUE_PROPRIETARY">PROPRIETARY</option>
          <option value="PROPRIETARY">PROPRIETARY/OPEN SOURCE</option>
          <option value="EMBEDDED_OPEN_SOURCE">OPEN SOURCE/PROPRIETARY</option>
          <option value="OPEN_SOURCE">OPEN SOURCE</option>
          <option value="OPEN_COMPONENT">OPEN SOURCE COMPONENT</option>
        </select>
      </th>
    </tr>
    <tr class="cursor-pointer" *ngIf="story.length > 0">
      <td (click)="goBack()" colspan="8">
        <i class="fas fa-folder-open"></i>&nbsp;&nbsp;...
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-scanAsset
    let-rowIndex="rowIndex"
    let-expanded="expanded"
    let-columns="columns"
  >
    <tr
      [ngStyle]="{
        cursor:
          scanAsset.node.scanAssetType == 'DIR' || scanAsset.node.matchCount > 0
            ? 'default'
            : 'not-allowed'
      }"
      (click)="!ignored(scanAsset) && gotoDetails(scanAsset)"
      *ngIf="
        scanAsset.node.status === 'ACCEPTED' ||
        scanAsset.node.scanAssetType === 'DIR'
      "
      [class.ignored]="ignored(scanAsset)"
    >
      <td class="name">
        <i
          *ngIf="scanAsset.node.scanAssetType === 'DIR'"
          class="fas fa-folder"
        ></i>
        <i
          *ngIf="scanAsset.node.scanAssetType != 'DIR'"
          class="far fa-file"
        ></i>

        <ng-container
          *ngTemplateOutlet="assetPopup; context: { $implicit: scanAsset }"
        ></ng-container>

        <i
          *ngIf="scanAsset.node.scanAssetType !== 'DIR'"
          triggers="mouseenter:mouseleave"
          [ngbPopover]="popContentWorkspace"
          container="body"
          style="color: #2dc6db; margin-left: 5px; cursor: pointer"
          class="fad fa-folder-tree"
        >
        </i>

        <!-- NAME -->
        <a
          [pRowToggler]="scanAsset.node.matchCount > 1 ? scanAsset : null"
          [ngStyle]="{
            cursor:
              scanAsset.node.scanAssetType == 'DIR' ||
              scanAsset.node.matchCount > 0
                ? 'pointer'
                : 'not-allowed'
          }"
          style="display: flex; align-items: center"
        >
          <span
            class="asset-name"
            [ngStyle]="{
              color:
                scanAsset.node.matchCount === 0 &&
                scanAsset.node.scanAssetType !== 'DIR'
                  ? 'gray'
                  : 'white'
            }"
          >
            <span
              class="name-text"
              [ngbTooltip]="getName(scanAsset.node.name)"
              container="body"
            >
              {{ getName(scanAsset.node.name) }}</span
            >
            <app-policy-violations
              *ngIf="
                scanAsset.node.embeddedAssets?.edges?.length === 1 &&
                scanAsset.node.scanAssetType != 'DIR' &&
                scanAsset.node.policyViolationNotation?.length > 0
              "
              [stylesClass]="'core-assets'"
              [policies]="scanAsset.node.policyViolationNotation"
              ngbTooltip="Policy Violations"
            ></app-policy-violations>
            <i
              [ngbTooltip]="'Copy asset id'"
              (click)="
                onCopyAssetID(
                  scanAsset.node.embeddedAssets?.edges[0]?.node?.assetMatchId
                )
              "
              *ngIf="scanAsset.node.embeddedAssets?.edges.length === 1"
              class="feather icon-copy"
            ></i>
          </span>

          <i
            *ngIf="scanAsset.node.embeddedAssets?.edges?.length > 1"
            [ngClass]="
              expanded
                ? 'pi pi-fw pi-chevron-circle-down'
                : 'pi pi-fw pi-chevron-circle-right'
            "
            style="vertical-align: middle; padding-bottom: 0.1em"
          ></i>
        </a>
        <!-- NAME -->

        <i
          class="far fa-clock ml-2"
          *ngIf="scanAsset.node.otCount"
          [placement]="'top'"
          [ngbTooltip]="'Origin Tracing: ' + scanAsset.node.otCount"
        ></i>
        <i
          class="fas fa-cubes"
          triggers="mouseenter:mouseleave"
          [ngbPopover]="popContent"
          style="color: #2dc6db; margin-left: 3px; margin-right: 5px"
          *ngIf="
            scanAsset.node.matchType === 'OPEN_COMPONENT' &&
            scanAsset.node.component
          "
        >
        </i>

        <ng-container *ngIf="scanAsset.node.loading">
          <i class="fa fa-spinner fa-spin ml-auto"></i>
        </ng-container>

        <ng-template #popContent>
          {{
            scanAsset.node.component.name +
              ':' +
              scanAsset.node.component.group +
              ':' +
              scanAsset.node.component.version
          }}
        </ng-template>
        <ng-template #popContentWorkspace>
          {{ scanAsset?.node?.workspacePath }}
        </ng-template>
      </td>

      <!-- matches start -->
      <td>
        {{ scanAsset?.node?.matchCount }} /
        {{ scanAsset?.node?.percentEmbedded | number : '1.0-0' }}%
      </td>
      <!-- matches end -->

      <!-- status score start -->
      <td>{{ ignored(scanAsset) ? 'IGNORED' : scanAsset.node.status }}</td>
      <!-- status score end -->

      <!-- Origin start -->

      <td
        *ngIf="scanAsset.node.scanAssetType != 'DIR'"
        [ngClass]="{
          'flex-wrapper': scanAsset.node.embeddedAssets?.edges.length === 1
        }"
      >
        <a
          class="origin-text"
          *ngIf="
            scanAsset.node.embeddedAssets?.edges.length === 1 &&
            scanAsset.node.embeddedAssets?.edges[0]?.node.matchRepository
              .repositoryOwner as owner
          "
          (click)="
            openInNewTab(
              scanAsset.node.embeddedAssets?.edges[0]?.node.matchRepository
                .repositoryCode,
              owner,
              scanAsset.node.embeddedAssets?.edges[0]?.node.matchRepository
                .repositoryName
            )
          "
          [ngbTooltip]="
            owner +
            '/' +
            scanAsset.node.embeddedAssets?.edges[0]?.node.matchRepository
              .repositoryName
          "
          container="body"
        >
          {{ owner }}/{{
            scanAsset.node.embeddedAssets?.edges[0]?.node.matchRepository
              .repositoryName
          }}
        </a>
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>

      <!-- Origin end -->

      <!-- versions score start -->
      <td
        *ngIf="
          scanAsset.node.scanAssetType != 'DIR' &&
          !(scanAsset.node.embeddedAssets?.edges.length > 1)
        "
        (click)="
          setSelectedEmbeddedAsset(
            scanAsset.node.embeddedAssets?.edges[0]?.node,
            assetVersionModal
          )
        "
        class="version-column"
      >
        {{
          scanAsset.node.embeddedAssets?.edges[0]?.node.earliestReleaseVersion
        }}
        <span
          *ngIf="
            scanAsset.node.embeddedAssets?.edges[0]?.node
              .earliestReleaseVersion !==
            scanAsset.node.embeddedAssets?.edges[0]?.node.latestReleaseVersion
          "
          >-
          {{
            scanAsset.node.embeddedAssets?.edges[0]?.node.latestReleaseVersion
          }}</span
        >
      </td>

      <td
        *ngIf="
          scanAsset.node.scanAssetType === 'DIR' ||
          scanAsset.node.embeddedAssets?.edges.length > 1
        "
      ></td>

      <!-- versions score end -->

      <!-- Licenses score start -->
      <td
        [ngClass]="{
          flex:
            scanAsset.node.embeddedAssets?.edges[0]?.node.matchLicenses.length >
              0 && !(scanAsset.node.embeddedAssets?.edges.length > 1)
        }"
        *ngIf="scanAsset.node.scanAssetType != 'DIR'"
      >
        <ng-container
          *ngIf="
            scanAsset.node.embeddedAssets?.edges.length === 1 &&
            scanAsset.node.embeddedAssets?.edges[0]?.node.matchLicenses.length
          "
        >
          <ng-container [ngSwitch]="scanAsset.node?.attributionStatus">
            <i
              *ngSwitchCase="'REQUIRED'"
              (click)="
                openAttributionModal(
                  scanAsset.node,
                  scanAsset.node.embeddedAssets?.edges[0].node,
                  0,
                  attributeProcessExecuteMessageModel
                )
              "
              [ngbTooltip]="
                attributionTooltip(scanAsset.node?.attributionStatus)
              "
              class="fa-regular fa-circle-plus attribution-icon red"
              [ngClass]="{ 'red-blurred': !isScmActionsAvailableForScan }"
            ></i>
            <i
              *ngSwitchCase="'PARTIAL'"
              (click)="
                openAttributionModal(
                  scanAsset.node,
                  scanAsset.node.embeddedAssets?.edges[0].node,
                  0,
                  attributeProcessExecuteMessageModel
                )
              "
              [ngbTooltip]="
                attributionTooltip(scanAsset.node?.attributionStatus)
              "
              class="fa-regular fa-circle-plus attribution-icon yellow"
              [ngClass]="{ 'yellow-blurred': !isScmActionsAvailableForScan }"
            ></i>
            <i
              *ngSwitchCase="'NOT_REQUIRED'"
              [ngbTooltip]="
                attributionTooltip(scanAsset.node?.attributionStatus)
              "
              class="fa-regular fa-circle-check attribution-icon green"
            ></i>

            <i
              *ngSwitchCase="'PR_CREATED'"
              [ngbTooltip]="
                attributionTooltip(scanAsset.node?.attributionStatus)
              "
              class="fa-regular fa-circle-check attribution-icon green"
            ></i>

            <i
              *ngSwitchCase="'NOT_SUPPORTED'"
              class="fa-regular fa-circle-xmark attribution-icon grey"
              [ngbTooltip]="
                attributionTooltip(scanAsset.node?.attributionStatus)
              "
            ></i>

            <i
              *ngSwitchCase="'NOT_AVAILABLE'"
              class="fa-regular fa-circle-xmark attribution-icon grey"
              [ngbTooltip]="
                attributionTooltip(scanAsset.node?.attributionStatus)
              "
            ></i>
          </ng-container>
        </ng-container>
        <!-- <i
          (click)="
            openAttributionModal(
              scanAsset.node,
              scanAsset.node.embeddedAssets?.edges[0].node,
              attributionLicensesModal,
              0
            )
          "
          *ngIf="
            scanAsset.node.isSupportedLicenseAnnotation &&
            scanAsset.node.embeddedAssets?.edges.length === 1 &&
            scanAsset.node.embeddedAssets?.edges[0]?.node.matchLicenses.length
          "
          [ngbTooltip]="'Attribute license'"
          class="fa-regular fa-circle-plus"
          style="margin-right: 5px; cursor: pointer"
          [ngStyle]="{
            color:
              scanAsset.node.embeddedAssets?.edges[0]?.node.matchLicenses[0]
                ?.attributionStatus === 'NOT_REQUIRED'
                ? '#11C15B'
                : '#C54105'
          }"
        ></i> -->
        <app-licenses-grouping-label
          *ngIf="scanAsset.node.embeddedAssets?.edges?.length === 1"
          [licenses]="
            scanAsset.node.embeddedAssets?.edges[0]?.node?.matchLicenses
          "
        ></app-licenses-grouping-label>
      </td>

      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <!-- Licenses score end -->

      <!-- Match score start -->
      <td
        [class]="'score-column ' + scanAsset.node.matchScore"
        *ngIf="scanAsset.node.scanAssetType != 'DIR'"
      >
        {{ scanAsset.node.matchScore }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
      <!-- Match score finish -->

      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'" style="display: flex">
        <span style="margin-right: 5px">{{
          !!scanAsset.node.matchType
            ? matchTypeVal2Caption(scanAsset.node.matchType)
            : ''
        }}</span>
        <img
          *ngIf="scanAsset.node.embeddedAssets?.edges.length === 1"
          style="
            margin-left: auto;
            color: white;
            cursor: pointer;
            filter: brightness(0) invert(1);
          "
          (click)="
            $event.stopPropagation();
            getScanRepository(
              scanAsset.node.embeddedAssets?.edges[0].node,
              scanAsset.node,
              simmtool
            )
          "
          ngbTooltip="Open SIMM"
          container="body"
          src="/assets/images/simm-icon.svg"
        />
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
    </tr>

    <!-- NOT ALLOWED FILES -->
    <tr
      style="cursor: not-allowed"
      *ngIf="
        scanAsset.node.status != 'ACCEPTED' &&
        scanAsset.node.scanAssetType != 'DIR'
      "
      [class.ignored]="ignored(scanAsset)"
    >
      <td class="name">
        <i
          *ngIf="scanAsset.node.scanAssetType === 'DIR'"
          class="fas fa-folder"
        ></i>
        <i
          *ngIf="scanAsset.node.scanAssetType != 'DIR'"
          class="far fa-file"
        ></i>

        <ng-container
          *ngTemplateOutlet="
            assetPopup;
            context: {
              $implicit: scanAsset
            }
          "
        ></ng-container>
        <i
          *ngIf="scanAsset.node.scanAssetType !== 'DIR'"
          triggers="mouseenter:mouseleave"
          [ngbPopover]="popContentWorkspace"
          container="body"
          style="
            color: #2dc6db;
            margin-left: 3px;
            margin-right: 5px;
            cursor: pointer;
          "
          class="fad fa-folder-tree"
        >
        </i>

        <span class="name-text" [ngbTooltip]="getName(scanAsset.node.name)">
          {{ getName(scanAsset.node.name) }}</span
        >

        <app-policy-violations
          *ngIf="
            scanAsset.node.scanAssetType != 'DIR' &&
            scanAsset.node.policyViolationNotation?.length > 0
          "
          [stylesClass]="'core-assets'"
          [policies]="scanAsset.node.policyViolationNotation"
          ngbTooltip="Policy Violations"
        ></app-policy-violations>
        <ng-template #popContentWorkspace>
          {{ scanAsset?.node?.workspacePath }}
        </ng-template>
      </td>

      <!-- MATCHES START -->
      <td>0 / 0%</td>

      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>

      <!-- MATCHES FINISH -->

      <td>{{ ignored(scanAsset) ? 'IGNORED' : scanAsset.node.status }}</td>

      <td></td>

      <td></td>

      <!-- Licenses start -->
      <td></td>
      <!-- Licenses finish -->

      <!-- score start -->
      <td></td>
      <!-- score finish -->

      <td *ngIf="scanAsset.node.scanAssetType != 'DIR'">
        {{
          !!scanAsset.node.matchType
            ? matchTypeVal2Caption(scanAsset.node.matchType)
            : ''
        }}
      </td>
      <td *ngIf="scanAsset.node.scanAssetType === 'DIR'"></td>
    </tr>

    <ng-template #assetPopup let-asset>
      <div class="action-menu-icon-wrapper">
        <div class="action-menu-popup-wrapper">
          <i class="feather icon-more-vertical" style="cursor: pointer"></i>

          <div class="action-menu-popup">
            <div class="action-menu">
              <button
                class="btn btn-outline"
                (click)="onIgnoreAsset($event, asset.node, !ignored(scanAsset))"
              >
                {{ ignored(scanAsset) ? 'Unignore' : 'Ignore' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-scanAsset let-rowIndex="rowIndex">
    <ng-container
      *ngFor="let match of scanAsset.node.embeddedAssets.edges; let i = index"
    >
      <tr
        class="custom-tr expanded"
        style="position: relative; cursor: default"
      >
        <td>
          <div style="display: flex; align-items: center">
            <i
              class="fa-solid fa-arrow-turn-down-right"
              style="margin-right: 5px; margin-left: 20px"
            ></i>

            <span class="name-text" [ngbTooltip]="match.node.name">
              {{ match.node.name }}</span
            >
            <app-policy-violations
              *ngIf="match.node.policyViolationNotation?.length > 0"
              [stylesClass]="'core-assets'"
              ngbTooltip="Policy Violations"
              [policies]="match.node.policyViolationNotation"
            ></app-policy-violations>
            <i
              style="margin-left: 3px; cursor: pointer"
              (click)="onCopyAssetID(match.node?.assetMatchId)"
              class="feather icon-copy"
              [ngbTooltip]="'Copy asset id'"
            ></i>
          </div>
        </td>
        <td>{{ 1 }} /{{ match.node.percentMatch | number : '1.1-2' }}%</td>
        <td>{{ scanAsset.node.status }}</td>
        <td
          [ngClass]="{
            'flex-wrapper': match.node.matchRepository.repositoryOwner
          }"
        >
          <a
            class="origin-text"
            (click)="
              openInNewTab(
                match.node.matchRepository.repositoryCode,
                match.node.matchRepository.repositoryOwner,
                match.node.matchRepository.repositoryName
              )
            "
            [ngbTooltip]="
              (match.node.matchRepository.repositoryCode | lowercase) +
              ':' +
              match.node.matchRepository.repositoryOwner +
              '/' +
              match.node.matchRepository.repositoryName
            "
          >
            {{ match.node.matchRepository.repositoryCode | lowercase }}
            : {{ match.node.matchRepository.repositoryOwner }}/{{
              match.node.matchRepository.repositoryName
            }}
          </a>
        </td>
        <td class="version-column">
          <span
            (click)="setSelectedEmbeddedAsset(match.node, assetVersionModal)"
          >
            {{ match.node.earliestReleaseVersion }} -
            {{ match.node.latestReleaseVersion }}</span
          >
        </td>
        <td style="display: flex; align-items: center">
          <ng-container *ngIf="match.node.matchLicenses?.length > 0">
            <ng-container
              [ngSwitch]="match.node?.matchLicenses[0]?.attributionStatus"
            >
              <i
                (click)="
                  openAttributionModal(
                    scanAsset.node,
                    match.node,
                    i,
                    attributeProcessExecuteMessageModel
                  )
                "
                *ngSwitchCase="'REQUIRED'"
                [ngbTooltip]="
                  attributionTooltip(
                    match.node?.matchLicenses[0]?.attributionStatus
                  )
                "
                class="fa-regular fa-circle-plus attribution-icon red"
                [ngClass]="{ 'red-blurred': !isScmActionsAvailableForScan }"
              ></i>
              <i
                *ngSwitchCase="'NOT_REQUIRED'"
                [ngbTooltip]="
                  attributionTooltip(
                    match.node?.matchLicenses[0]?.attributionStatus
                  )
                "
                class="fa-regular fa-circle-check attribution-icon green"
              ></i>

              <i
                *ngSwitchCase="'PR_CREATED'"
                [ngbTooltip]="
                  attributionTooltip(
                    match.node?.matchLicenses[0]?.attributionStatus
                  )
                "
                class="fa-regular fa-circle-check attribution-icon green"
              ></i>

              <i
                *ngSwitchCase="'PARTIAL'"
                (click)="
                  openAttributionModal(
                    scanAsset.node,
                    match.node,
                    i,
                    attributeProcessExecuteMessageModel
                  )
                "
                [ngbTooltip]="
                  attributionTooltip(
                    match.node?.matchLicenses[0]?.attributionStatus
                  )
                "
                class="fa-regular fa-circle-plus attribution-icon yellow"
                [ngClass]="{ 'yellow-blurred': !isScmActionsAvailableForScan }"
              ></i>

              <i
                *ngSwitchCase="'NOT_AVAILABLE'"
                class="fa-regular fa-circle-xmark attribution-icon grey"
                [ngbTooltip]="
                  attributionTooltip(
                    match.node?.matchLicenses[0]?.attributionStatus
                  )
                "
              ></i>

              <i
                *ngSwitchCase="'NOT_SUPPORTED'"
                class="fa-regular fa-circle-xmark attribution-icon grey"
                [ngbTooltip]="
                  attributionTooltip(
                    match.node?.matchLicenses[0]?.attributionStatus
                  )
                "
              ></i>
            </ng-container>
          </ng-container>

          <app-licenses-grouping-label
            style="height: 25px"
            [licenses]="match.node.matchLicenses"
          ></app-licenses-grouping-label>
        </td>
        <td [class]="'score-column ' + match.node.matchScore">
          {{ match.node.matchScore }}
        </td>
        <td style="display: flex">
          <span style="margin-right: 5px">{{ scanAsset.node.matchType }}</span>
          <img
            (click)="
              getScanRepository(match.node, scanAsset.node, simmtool);
              $event.stopPropagation()
            "
            style="
              margin-left: auto;
              color: white;
              cursor: pointer;
              filter: brightness(0) invert(1);
            "
            ngbTooltip="Open SIMM"
            container="body"
            src="/assets/images/simm-icon.svg"
          />
        </td></tr
    ></ng-container>
  </ng-template>
</p-table>

<app-simm #simmtool></app-simm>

<!-- Asset Releases Dialog -->
<app-ui-modal
  #assetVersionModal
  dialogClass="modal-dialog-centered popup-Width-fix"
>
  <div class="app-modal-header">
    <h5 class="modal-title">{{ selectedEmbeddedAsset?.name }}</h5>
    <button
      type="button"
      class="close basic-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="assetVersionModal.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <perfect-scrollbar [style.max-height]="'550px'" class="overflowshadowTop">
      <p-table
        #dt
        [value]="selectedEmbeddedAsset?.matchGroups.edges"
        [paginator]="false"
        [rows]="50"
        tableStyleClass="table table-striped table-bordered table-hover dataTable no-footer table-width"
        [autoLayout]="false"
        [filterDelay]="0"
        [globalFilterFields]="['name']"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 80px">Asset ID</th>
            <th>Earliest Version/Date</th>
            <th>Latest Version/Date</th>
            <th>License</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-release>
          <tr>
            <td style="width: 80px">
              {{ release.node.assetMatchId | slice : 32 }}
            </td>
            <td>
              {{ release.node.earliestReleaseVersion }} /
              {{ release.node.earliestReleaseDate | date : 'MMMM d, y' }}
            </td>
            <td>
              {{ release.node.latestReleaseVersion }} /
              {{ release.node.latestReleaseDate | date : 'MMMM d, y' }}
            </td>
            <!--<td>{{release.node.earliestReleaseVersion}}</td>
                <td>{{release.node.earliestReleaseDate | date :'MMMM d, y'}}</td>-->
            <td style="border-left: 1px solid gray">
              <div *ngFor="let license of release?.node.licensesByMatchGroup">
                <span
                  *ngIf="license.category === 'COPYLEFT_STRONG'"
                  title="Copyleft Strong"
                  class="badge m-r-5 badge-light-danger"
                  >COPYLEFT STRONG</span
                >
                <span
                  *ngIf="license.category === 'COPYLEFT_WEAK'"
                  title="Copyleft Weak"
                  class="badge m-r-5 badge-light-warning"
                  >COPYLEFT WEAK</span
                >
                <span
                  *ngIf="license.category === 'COPYLEFT_PARTIALK'"
                  title="Copyleft Partial"
                  class="badge m-r-5 badge-light-warning"
                  >COPYLEFT PARTIAL</span
                >
                <span
                  *ngIf="license.category === 'COPYLEFT_LIMITED'"
                  title="Copyleft Limited"
                  class="badge m-r-5 badge-light-warning"
                  >COPYLEFT LIMITED</span
                >
                <span
                  *ngIf="license.category === 'COPYLEFT'"
                  title="Copyleft"
                  class="badge m-r-5 badge-light-warning"
                  >COPYLEFT</span
                >
                <span
                  *ngIf="license.category === 'PERMISSIVE'"
                  title="Permissive"
                  class="badge m-r-5 badge-light-success"
                  >PERMISSIVE</span
                >
                <span
                  *ngIf="license.category === 'PROPRIETARY'"
                  title="Proprietary"
                  class="badge m-r-5 badge-light-warning"
                  >PROPRIETARY</span
                >
                {{ license.name }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </perfect-scrollbar>
  </div>
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="assetVersionModal.hide()"
    >
      Close
    </button>
  </div>
</app-ui-modal>

<app-ui-modal #attributeProcessExecuteMessageModel>
  <div class="app-modal-header">
    <h5 class="modal-title">Attribution Process Executing</h5>
  </div>
  <div class="app-modal-body">
    <p>Attribution process executing. Please wait a few seconds.</p>
  </div>
  <div class="app-modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="
        attributeProcessExecutionModel(attributeProcessExecuteMessageModel)
      "
    >
      Close
    </button>
  </div>
</app-ui-modal>
