<div class="modal-header">
  <h3 class="modal-title" id="modal-basic-title">
    Move {{ project.name }}
    <i
      *ngIf="isMovingModuleLoading"
      style="margin-left: 5px"
      class="fa-duotone fa-solid fa-spinner fa-spin"
    ></i>
  </h3>
  <button
    type="button"
    class="close basic-close"
    autofocus="false"
    data-dismiss="modal"
    aria-label="Close"
    [disabled]="isMovingModuleLoading"
    (click)="$event.stopPropagation(); closeDialog()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body scp-projects">
  <p style="font-size: 16px">Choose new SCP:</p>

  <mat-form-field
    class="search"
    style="margin-bottom: 10px; padding-right: 16px"
  >
    <mat-label>Project Name</mat-label>
    <input
      matInput
      placeholder="Type in project name"
      [(ngModel)]="projectSearchName"
      (ngModelChange)="getScpProjects(projectSearchName)"
    />
    <button
      mat-button
      *ngIf="projectSearchName"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="projectSearchName = ''; getScpProjects('')"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div *ngIf="loading" class="loading-wrapper">
    <i class="fa-duotone fa-solid fa-spinner fa-spin"></i>
  </div>

  <div *ngIf="!loading" class="content-wrapper">
    <ng-container *ngFor="let scps of scpProjects">
      <p style="font-weight: 700; font-size: 16px">{{ scps.entity.name }}</p>
      <ng-container *ngFor="let project of scps.scpProjects">
        <mat-card
          (click)="
            moveProject(
              project.scpProjectId,
              project.scpProjectType !== entitySubType
            )
          "
          [ngbTooltip]="
            project.scpProjectType !== entitySubType
              ? 'The types of the projects are incompatible'
              : 'Move to this project'
          "
          container="modal-body"
          [openDelay]="project.scpProjectType !== entitySubType ? 0 : 1000"
          *ngIf="project.scpProjectId !== entityId"
          [ngClass]="{ disabled: project.scpProjectType !== entitySubType }"
        >
          <span class="badge">
            {{
              project.scpProjectType === 'AI_CERTIFY'
                ? 'AIC'
                : project.scpProjectType === 'CODECERTIFY'
                ? 'CC'
                : 'TEAM'
            }}</span
          >
          <span>{{ project.scpProjectName }}</span
          ><i
            class="fa-solid fa-right-left-large"
            [ngClass]="{ red: project.scpProjectType !== entitySubType }"
          ></i>
        </mat-card>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="projectSearchName && !loading && scpProjects.length === 0">
    <ngb-alert type="info" [dismissible]="false">
      No projects have been found for your request</ngb-alert
    >
  </div>
</div>
