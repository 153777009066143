// todo: ref: rename to MessageResources
export const MESSAGES = {
  ACCOUNT_CREATE_VIA_INVITE: 'Create account via invite link.',
  ACCOUNT_UPDATE_SUCCESS:
    'Thank you for providing additional info. Your data saved successfully.',
  ACCOUNT_UPDATE_ERROR:
    'Unexpected error occurred while trying to save your data.',
  ACCOUNT_APPROVAL_AWAIT: 'Awaiting account approval.',
  API_KEY_ERROR: 'API key title field is required.',
  API_KEY_PERMISSIONS_OR_ROLES: 'API key role or permission is required.',
  API_KEY_GENERATE_SUCCESS: 'API key generated successfully.',
  API_KEY_GENERATE_ERROR:
    'Unexpected error occurred while trying to generate API key.',
  API_KEY_SAVE_SUCCESS: 'API key saved successfully.',
  API_KEY_SAVE_ERROR: 'Unexpected error occurred while trying to save API key.',
  API_KEY_REMOVE_CONFIRM: 'Are you sure you want to delete API key?',
  API_KEY_REMOVE_SUCCESS: 'API key removed successfully.',
  API_KEY_REMOVE_ERROR:
    'Unexpected error occurred while trying to remove API key.',
  ASSET: {
    FILTER: {
      EMBEDDED_AND_OPENSOURCE: 'Embedded and open source',
    },
  },
  AUTHENTICATE_NEW_USER_SESSION:
    'Please authenticate with the new user session.',
  CONDITION_GROUP_ERROR: 'Main condition group must contain conditions.',
  CONDITION_TYPE_ERROR: 'Condition type must be filled.',
  CONDITION_OPERATOR_ERROR: 'Condition operator must be filled.',
  CONDITION_VALUE_ERROR: 'Condition value must be filled.',
  ACTION_VALUE_EMAILS_ERROR:
    'Only valid emails separated by a coma are accepted.',
  ACTION_VALUE_SLACK_ERROR: 'At least one slack channel should be added',
  ENTITY_TITLE: 'Undefined entity',
  ENTITY_MESSAGE:
    'Contact your organization administrator to have an entity assigned to you.',
  ERROR_TITLE: 'Error!',
  ERROR_MESSAGE: 'Something went wrong!',
  GRAPHQL_ERROR_MESSAGE:
    'An unexpected error occurred while trying to fetch the requested data.',
  LICENSE: {
    DISCOVERY: {
      DECLARED: 'Declared',
      DISCOVERED: 'Discovered',
    },
    ORIGIN: {
      COMPONENT: 'Component',
      REPOSITORY: 'Repository',
      REPOSITORY_META: 'Repository',
      REPOSITORY_LICENSE: 'Repository License File',
      ASSET: 'Asset',
    },
  },
  POLICY_NAME_ERROR: 'Policy name field is required.',
  POLICY_ACTIONS_ERROR: 'Policy actions must be filled.',
  POLICY_SAVE_SUCCESS: 'Policy saved successfully.',
  POLICY_SAVE_ERROR: 'Unexpected error occurred while trying to save policy.',
  POLICY_UPDATE_SUCCESS: 'Policy state changed successfully.',
  POLICY_UPDATE_ERROR:
    'Unexpected error occurred while trying to change policy state.',
  POLICY_REMOVE_CONFIRM: 'Are you sure you want to delete the policy?',
  POLICY_REMOVE_SUCCESS: 'Policy removed successfully.',
  POLICY_REMOVE_ERROR:
    'Unexpected error occurred while trying to remove policy.',
  POLICY_ENABLE_CONFIRM: 'Are you sure you want to enable the policy?',
  POLICY_DISABLE_CONFIRM: 'Are you sure you want to disable the policy?',
  PROJECT_DATA_NOT_FOUND: 'Project data not found!',
  RETURNED_TO_PAGE: 'Returned to page.',
  ROLE_SAVE_SUCCESS: 'Role saved successfully.',
  ROLE_SAVE_ERROR: 'Unexpected error occurred while trying to save role.',
  ROLE_REMOVE_CONFIRM: 'Are you sure you want to delete the role',
  ROLE_REMOVE_SUCCESS: 'Role removed successfully.',
  ROLE_REMOVE_ERROR: 'Unexpected error occurred while trying to remove role.',
  ROLES_SAVE_SUCCESS: 'Roles added successfully.',
  ROLES_SAVE_ERROR: 'Unexpected error occurred while trying to add roles.',
  STATUS_0:
    'Backend server is restarting. Please wait few moments and try to refresh the page.',
  STATUS_400:
    'The request had bad syntax or was inherently impossible to be satisfied.',
  STATUS_403:
    'You do not have sufficient rights to access the requested resource.',
  STATUS_404: 'The server has not found anything matching the URI given.',
  STATUS_405:
    'A request was made of a page using a request method not supported by that page',
  STATUS_406:
    'The server can only generate a response that is not accepted by the client',
  STATUS_407: 'The client must first authenticate itself with the proxy',
  STATUS_408: 'The server timed out waiting for the request',
  STATUS_415:
    'The server will not accept the request, because the media type is not supported ',
  STATUS_500:
    'The server encountered an unexpected condition which prevented it from fulfilling the request.',
  STATUS_501: 'The server does not support the facility required.',
  STATUS_502:
    'Backend server is restarting at the moment. Please wait several seconds and repeat your request/action.',
  STATUS_503: 'The server is currently unavailable (overloaded or down)',
  STATUS_504:
    'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server',
  STATUS_505:
    'The server does not support the HTTP protocol version used in the request',
  TOKEN_NOT_FOUND: 'JWT Token not found!',
  TOKEN_EXPIRED: 'Your access token has expired, please login again.',
  USER_SAVE_SUCCESS: 'User saved successfully.',
  USER_SAVE_ERROR: 'Unexpected error occurred while trying to save user.',
};
