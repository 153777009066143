import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

import { EMPTY, forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import gql from 'graphql-tag';

import { ProjectQuery, Scan, ScanEdge, ScanQuery } from '@app/models';

import { NextConfig } from '@app/app-config';
import { MESSAGES } from '@app/messages/messages';

import { AlertService } from '@app/services/core/alert.service';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import { UserPreferenceService } from '@app/services/core/user-preference.service';
import { CompositeService } from '@app/services/composite/composite.service';

@Injectable()
export class ProjectDashboardService {
  constructor(
    private coreGraphQLService: CoreGraphQLService,
    private alertService: AlertService,
    private userPreferenceService: UserPreferenceService
  ) {}

  // get project data
  getProject(projectId: string, first, isComposite: boolean = false) {
    return this.coreGraphQLService.coreGQLReqWithQuery<ProjectQuery>(
      gql`
            query {
                project(projectId:"${projectId}") {
                    projectId,
                    parentProjectId,
                    entityId,
                    orgId,
                    name,
                    tags,
                    
                    projectMetricsGroup {
                        projectMetrics{
                            measureDate
                            vulnerabilityMetrics {
                                severityMetrics
                            }
                            assetMetrics {
                                assetCompositionMetrics
                            }
                            componentMetrics {
                                vulnerabilityMetrics
                                licenseCategoryMetrics
                                licenseFamilyMetrics
                                licenseNameMetrics
                            }
                            licenseMetrics {
                                licenseCategoryMetrics
                                licenseFamilyMetrics
                                licenseNameMetrics
                            }
                            supplyChainMetrics {
                                supplyChainMetrics
                            }
                        }
                    }
                    
                    scans(first:${first}) {
                        totalCount
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        
                        edges {
                            node {
                                scanId,
                                orgId,
                                projectId,
                                branch,
                                tag,
                                version
                                versionHash
                                created,
                                status,
                                errorMsg,
                                log,
                                otMetaData
                                scanAssets {
                                  otCount
                                }
                                components {
                                  totalCount
                                }
                                scanMetricsSummary(isComposite: ${isComposite}) {
                                    componentCountMetrics {
                                        totalCount
                                        riskyLicenses
                                        vulnerableComponents
                                    }
                                    vulnerabilityMetrics {
                                        critical,
                                        high,
                                        medium,
                                        low,
                                        info,
                                    },
                                    componentVulnerabilityMetrics {
                                        critical,
                                        high,
                                        medium,
                                        low,
                                        info,
                                        unassigned
                                    }
                                    licenseMetrics {
                                        copyleftStrong,
                                        copyleftWeak,
                                        copyleftPartial,
                                        copyleftLimited,
                                        copyleft,
                                        custom,
                                        dual,
                                        permissive,
                                        proprietary,
                                        proprietaryFree,
                                    },
                                    supplyChainMetrics {
                                        risk
                                        quality
                                    }
                                    assetMetrics {
                                        embedded,
                                        openSource,
                                        unique
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `,
      'no-cache'
    );
  }

  getAllScanData(
    scanId: string,
    defaultPage,
    scanAssetDetails: any,
    componentPage,
    vulPage,
    licensePage,
    compositeSwitch = false
  ) {
    // Scan Asset Config Start
    // let parentId = (scanAssetDetails.parentScanAssetId.length > 0) ?
    // 'parentScanAssetId: \"' + scanAssetDetails.parentScanAssetId + '\", ' : "";
    const filterArg = 'filter: "' + scanAssetDetails.filter + '"';
    const firstArg = !!scanAssetDetails.first
      ? `first: ${scanAssetDetails.first}`
      : '';
    // Scan Asset Config End

    return this.coreGraphQLService.coreGQLReqWithQuery<Scan>(
      gql`
          query {
            scan(scanId:"${scanId}") {
                scanId,
                vulnerabilities(isComposite: ${compositeSwitch}, first:${vulPage}) {
                  pageInfo {
                     hasNextPage
                     hasPreviousPage
                     startCursor
                     endCursor
                   }
                   totalCount
                    edges {
                        node {
                            components {
                              edges {
                                node {
                                  group,
                                  name,
                                  version,
                                  componentId,
                                  componentType,
                                  componentDiscoveryMethod,
                                  workspaceRelativeFilePath,
                                  vulnLinkCorrect
                                }
                              }
                            }
                            vulnerabilityId,
                            vulnerabilityAlias,
                            source,
                            recommendation,
                            vulnerableVersions,
                            patchedVersions
                            published,
                            cwe{
                                cweId,
                                name
                            },
                            cvssV2BaseScore,
                            cvssV3BaseScore,
                            severity
                        }
                    }
                }


                components(isComposite: ${compositeSwitch}, first:${componentPage}) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  totalCount
                  edges {
                    node {
                      componentId,
                      name,
                      group,
                      version,
                      isInternal,
                      componentType,
                      componentLocation,
                      componentDiscoveryMethod,
                      dependencyManagerType,
                      licenses {
                        edges {
                          node {
                            licenseId,
                            name,
                            category,
                            spdxId,
                            licenseDiscovery
                          }
                        }
                      }
                      vulnerabilities {
                        edges {
                          node {
                            vulnerabilityId,
                            severity,
                            patchedVersions
                          }
                        }
                      }
                      metrics {
                        critical,
                        high,
                        medium,
                        low,
                        unassigned,
                        vulnerabilities,
                        suppressed,
                        findingsTotal,
                        findingsAudited,
                        findingsUnaudited,
                        inheritedRiskScore,
                        firstOccurrence,
                        lastOccurrence
                      }
                    }
                  }
                }


                licenses(isComposite: ${compositeSwitch}, first:${licensePage}) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  totalCount
                  edges {
                    node {
                      licenseId,
                      spdxId
                      name,
                      category,
                      style,
                      type,
                      spdxId,
                      publicationYear,
                      isOsiApproved,
                      isFsfLibre,
                      licenseDiscovery,
                      licenseOrigin,
                      trustLevel,

                    }
                  }
                }
            }
          }
      `,
      'no-cache'
    );
  }

  // Get Scan Vulnerabilities
  getScanVulnerabilities(scanId: string, defaultPage) {
    return this.coreGraphQLService.coreGQLReqWithQuery<Scan>(gql`
          query {
            scan(scanId:"${scanId}") {
                scanId,
                vulnerabilities(first:${defaultPage}) {
                  pageInfo {
                     hasNextPage
                     hasPreviousPage
                     startCursor
                     endCursor
                   }
                   totalCount
                    edges {
                        node {
                            components {
                              edges {
                                node {
                                  group,
                                  name,
                                  version,
                                  componentId
                                }
                              }
                            }
                            vulnerabilityId,
                            vulnerabilityAlias,
                            source,
                            recommendation,
                            vulnerableVersions,
                            patchedVersions
                            published,
                            cwe{
                                cweId,
                                name
                            },
                            cvssV2BaseScore,
                            cvssV3BaseScore,
                            severity
                        }
                    }
                }
            }
          }
      `);
  }

  // get scan components
  getScanComponents(scanId: string, defaultPage) {
    return this.coreGraphQLService.coreGQLReqWithQuery<ScanQuery>(gql`
        query {
          scan(scanId:"${scanId}") {
            scanId,
            components(first:${defaultPage}) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              totalCount
              edges {
                node {
                  componentId,
                  name,
                  group,
                  version,
                  isInternal,
                  componentType,
                  componentLocation,
                  componentDiscoveryMethod,
                  licenses {
                    edges {
                      node {
                        licenseId,
                        name,
                        category
                      }
                    }
                  }
                  vulnerabilities {
                    edges {
                      node {
                        vulnerabilityId,
                        severity,
                        patchedVersions
                      }
                    }
                  }
                  metrics {
                    critical,
                    high,
                    medium,
                    low,
                    unassigned,
                    vulnerabilities,
                    suppressed,
                    findingsTotal,
                    findingsAudited,
                    findingsUnaudited,
                    inheritedRiskScore,
                    firstOccurrence,
                    lastOccurrence
                  }
                }
              }
            }
          }
        }
    `);
  }

  // get scan license
  getScanLicenses(scanId: string, defaultPage) {
    return this.coreGraphQLService.coreGQLReqWithQuery<ScanQuery>(gql`
      query {
         scan(scanId:"${scanId}") {
           scanId,
           licenses(first:${defaultPage}) {
             pageInfo {
               hasNextPage
               hasPreviousPage
               startCursor
               endCursor
             }
             totalCount
             edges {
               node {
                 licenseId,
                 spdxId
                 name,
                 category,
                 style,
                 type,
                 spdxId,
                 publicationYear,
                 isOsiApproved,
                 isFsfLibre
               }
             }
           }
         }
       }
   `);
  }

  // set project tags
  setProjectTags(projectId: string, tags: string[]): any {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql` mutation { setProjectTags(projectId: "${projectId}", tags: "${tags}") {
                    projectId
            }}`
    );
  }

  getProjectData(projectId, isComposite) {
    return this.getProject(
      projectId,
      Number(
        this.userPreferenceService.getItemPerPageByModuleAndComponentName(
          'Project',
          'Scan'
        )
      ),
      isComposite
    );
  }

  getAllInitProjectData(projectId, isComposite) {
    return this.getProject(
      projectId,
      Number(
        this.userPreferenceService.getItemPerPageByModuleAndComponentName(
          'Project',
          'Scan'
        )
      ),
      isComposite
    ).pipe(
      mergeMap((data) => {
        let scanEdge: ScanEdge;

        const lastScanSelected =
          this.userPreferenceService.getLastScanSelectedByModule('Project');

        if (
          lastScanSelected &&
          lastScanSelected.lastSelectedScanId &&
          lastScanSelected.lastSelectedScanId !== ''
        ) {
          scanEdge = data.data.project.scans.edges.find(
            (edge) => edge.node.scanId === lastScanSelected.lastSelectedScanId
          );
        }

        if (!scanEdge) {
          scanEdge = data.data.project.scans.edges.reduce((a, b) =>
            a.node.created > b.node.created ? a : b
          );
        }

        const scanID = scanEdge.node.scanId;

        if (data.data.project && scanID) {
          const componentPage =
            this.userPreferenceService.getItemPerPageByModuleAndComponentName(
              'Project',
              'Components'
            );
          const vulPage =
            this.userPreferenceService.getItemPerPageByModuleAndComponentName(
              'Project',
              'Vulnerabilities'
            );
          const licensePage =
            this.userPreferenceService.getItemPerPageByModuleAndComponentName(
              'Project',
              'Licenses'
            );

          const scanAssetDetails = {
            parentScanAssetId: '',
            filter: '',
            first: Number(
              this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                'Project',
                'Assets'
              )
            ),
          };

          const scan$ = this.getAllScanData(
            scanID,
            NextConfig.config.defaultPageSize,
            scanAssetDetails,
            componentPage,
            vulPage,
            licensePage,
            isComposite
          );

          return forkJoin([scan$]);
        } else {
          this.alertService.alertBox(
            MESSAGES.PROJECT_DATA_NOT_FOUND,
            MESSAGES.ERROR_TITLE,
            'error'
          );

          return EMPTY;
        }
      })
    );
  } // getAllInitProjectData
}

@Injectable()
export class GetProjectData implements Resolve<Observable<any>> {
  constructor(
    private projectDashboardService: ProjectDashboardService,
    private userPreferenceService: UserPreferenceService,
    private compositeService: CompositeService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const projectId: string = route.paramMap.get('projectId');
    const isShowCompositeData = this.compositeService.isShowComposite();
    return this.projectDashboardService.getProject(
      projectId,
      Number(
        this.userPreferenceService.getItemPerPageByModuleAndComponentName(
          'Project',
          'Scan'
        )
      ),
      isShowCompositeData
    );
  }
}

@Injectable()
export class ProjectDashboardResolver implements Resolve<Observable<any>> {
  constructor(
    private projectDashboardService: ProjectDashboardService,
    private userPreferenceService: UserPreferenceService,
    private alertService: AlertService,
    private compositeService: CompositeService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const projectId = route.paramMap.get('projectId');

    const isShowCompositeData = this.compositeService.isShowComposite();

    return this.projectDashboardService
      .getProject(
        projectId,
        Number(
          this.userPreferenceService.getItemPerPageByModuleAndComponentName(
            'Project',
            'Scan'
          )
        ),
        isShowCompositeData
      )
      .pipe(
        mergeMap((data) => {
          let scanEdge: ScanEdge;

          const lastScanSelected =
            this.userPreferenceService.getLastScanSelectedByModule('Project');

          if (
            lastScanSelected &&
            lastScanSelected.lastSelectedScanId &&
            lastScanSelected.lastSelectedScanId !== ''
          ) {
            scanEdge = data.data.project.scans.edges.find(
              (edge) => edge.node.scanId === lastScanSelected.lastSelectedScanId
            );
          }

          if (!scanEdge) {
            scanEdge = data.data.project.scans.edges.reduce((a, b) =>
              a.node.created > b.node.created ? a : b
            );
          }

          const scanID = scanEdge.node.scanId;

          if (data.data.project && scanID) {
            const componentPage =
              this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                'Project',
                'Components'
              );
            const vulPage =
              this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                'Project',
                'Vulnerabilities'
              );
            const licensePage =
              this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                'Project',
                'Licenses'
              );

            const scanAssetDetails = {
              parentScanAssetId: '',
              filter: '',
              first: Number(
                this.userPreferenceService.getItemPerPageByModuleAndComponentName(
                  'Project',
                  'Assets'
                )
              ),
            };

            const scan$ = this.projectDashboardService.getAllScanData(
              scanID,
              NextConfig.config.defaultPageSize,
              scanAssetDetails,
              componentPage,
              vulPage,
              licensePage,
              isShowCompositeData
            );

            return forkJoin([scan$]);
          } else {
            this.alertService.alertBox(
              MESSAGES.PROJECT_DATA_NOT_FOUND,
              MESSAGES.ERROR_TITLE,
              'error'
            );

            return EMPTY;
          }
        })
      );
  }
}
