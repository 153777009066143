export class License {
  licenseId: string;
  license: any;
  spdxId: string;
  name: string;
  body: string;
  description: string;
  isOsiApproved: boolean;
  isFsfLibre: boolean;
  licenseDiscovery: string; //DISCOVERED
  isDeprecated: boolean;
  category: string; //PERMISSIVE
  style: string;
  publicationYear: number;
  type: string;
  attributes: LicenseAttribute[];
  components: any;
  compatible: string;
  incompatible: string;
  notes: string;
  isOrgLicense: boolean;
  node?: any;
  rejected?: boolean;
  licenseAssetAttribution: any;
  licenseOrigin: string; // ASSET
  trustLevel: string; // LEVEL_1 LEVEL_2
  needIncludeInCode?: boolean;
  attributionStatus?: string;
  customerLicenseId?: string;
}

export interface LicenseQueryNode {
  edges: Array<{ node: License }>;
}

export class LicenseAttribute {
  attributeType: string;
  key: string;
}

export type LicenseQuery = {
  license: License;
};

export enum LicenseCategory {
  UNDEFINED = 'Undefined',
  PROPRIETARY_FREE = 'Proprietary Free',
  PROPRIETARY = 'Proprietary',
  PUBLIC_DOMAIN = 'Public Domain',
  PERMISSIVE = 'Permissive',
  COPYLEFT_WEAK = 'Copyleft Weak',
  COPYLEFT_STRONG = 'Copyleft Strong',
  COPYLEFT_PARTIAL = 'Copyleft Partial',
  COPYLEFT_LIMITED = 'Copyleft Limited',
  COPYLEFT = 'Copyleft',
}

export class LicenseCategoryPair {
  id: string;
  name: string;
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class LicenseCategories {
  private static categories: LicenseCategoryPair[] = undefined;

  public static values(): LicenseCategoryPair[] {
    if (LicenseCategories.categories !== undefined) {
      return LicenseCategories.categories;
    }

    LicenseCategories.categories = Object.keys(LicenseCategory).map(
      (e) => new LicenseCategoryPair(e, LicenseCategory[e])
    );

    return LicenseCategories.categories;
  }
}

export class LicenseData {
  licenseId: string;
  name: string;
  body: string;
  licenseCategory: string;

  constructor(
    licenseId: string,
    name: string,
    body: string,
    licenseCategory: string
  ) {
    this.licenseId = licenseId;
    this.name = name;
    this.body = body;
    this.licenseCategory = licenseCategory;
  }
}

export class LicenseUrlAndContent {
  url: string;
  content: string;
  provenance: string;
}
