import { Component } from '@angular/core';
import { ScanAsset } from '@app/models';
import { AlertService } from '@app/services/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-license-attribution-modal',
  templateUrl: './license-attribution-modal.component.html',
  styleUrls: ['./license-attribution-modal.component.scss'],
})
export class LicenseAttributionModalComponent {
  public selectedAsset: ScanAsset;
  public selectedLicenses: any = {};
  public licenses: Array<string> = [];
  public attributionComment: string;
  public attributeSubject: Subject<{
    selectedLicenses: any;
    ignore: boolean;
    attributionComment: string;
  }> = new Subject();

  public allSelected: boolean = false;

  public constructor(
    private activeModal: NgbActiveModal,
    private alertService: AlertService
  ) {}

  public selectAllLicenses(event: Event): void {
    const { checked } = event.target as HTMLInputElement;

    Object.keys(this.selectedLicenses).forEach((key: string) =>
      checked
        ? (this.selectedLicenses[key] = true)
        : (this.selectedLicenses[key] = false)
    );
  }

  public checkIfAllSelected(): void {
    const allSelected: boolean = Object.keys(this.selectedLicenses).every(
      (key: string) => this.selectedLicenses[key]
    );
    if (allSelected) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  public hasSelectedLicenses(): boolean {
    return Object.keys(this.selectedLicenses).some(
      (key: string) => this.selectedLicenses[key]
    );
  }

  public close(): void {
    this.activeModal.close();
  }

  public attributeAsset(ignore: boolean): void {
    if (!ignore) {
      if (!this.hasSelectedLicenses()) {
        this.alertService.alertBox(
          'To attribute asset you have to select at least one of license which will be used for attribution',
          '',
          'info'
        );
        return;
      }
    }

    this.attributeSubject.next({
      selectedLicenses: this.selectedLicenses,
      ignore,
      attributionComment: this.attributionComment,
    });
  }
}
