import { Injectable } from '@angular/core';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import gql from 'graphql-tag';
import { ApolloQueryResult, WatchQueryFetchPolicy } from 'apollo-client';
import { GraphQLPage } from '@app/models/paging';
import { Observable } from 'rxjs';
import { License, ScanAssetsTreeConnection } from '@app/models';
import { UtilsService } from './utils.service';
import { FetchResult } from 'apollo-link';

export interface CardReviewData {
  assetPath: string;
  entityId: string;
  repositoryId: string;
  scanId: string;
  filter?: string;
}

@Injectable()
export class AssetService {
  constructor(
    private coreGraphQLService: CoreGraphQLService,
    private utilsService: UtilsService
  ) {}

  public getScanRepositoryInfo(scanId: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
              query {
                  scan(scanId: "${scanId}") {
                      
                      scanRepository {
                          repositoryOwner,
                          repositoryName,
                          repositoryEndpointType,
                          registrationId
                      }
                    }   
                }`
    );
  }

  getAssetsForAudit(
    scanId: string,
    noCache?: boolean,
    filter?: string,
    graphQLPage?: GraphQLPage
  ): Observable<
    ApolloQueryResult<{
      scan: {
        scanRepository: any;
        auditScanAssets: ScanAssetsTreeConnection;
      };
    }>
  > {
    // todo: ref: We have these lines almost in each method that calls GQL.
    //            Let's put them into object and call single method adopt values.
    //            [task: https://github.com/threatrix/product/issues/1532]

    const compFilterArg = !!filter ? 'filter: "' + filter + '"' : '';
    const firstArg = !!graphQLPage?.first ? `first: ${graphQLPage?.first}` : '';
    const lastArg = !!graphQLPage?.last ? `last: ${graphQLPage?.last}` : '';
    const afterArg = graphQLPage?.after
      ? `, after: "${graphQLPage?.after}"`
      : '';
    const beforeArg = graphQLPage?.before
      ? `, before: "${graphQLPage?.before}"`
      : '';

    let fetchPolicy: WatchQueryFetchPolicy = 'cache-first';
    if (noCache) {
      fetchPolicy = 'no-cache';
    }

    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query {
                scan(scanId: "${scanId}") {
                    
                    scanRepository {
                        repositoryOwner,
                        repositoryName,
                        repositoryEndpointType,
                        registrationId
                    }
            
                    auditScanAssets(${compFilterArg}${firstArg}${lastArg}${afterArg}${beforeArg}) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        totalCount
                        edges {
                            node {
                                orgId
                                scanId
                                scanAssetId
                                name
                                
                                auditorFullName
                                
                                reviewed
                                ignored
                                
                                status
                                workspacePath
                                matchCount
                                percentEmbedded
                                averagePercentCopied
                                projectPath
                                assetRepositoryUrl {
                                    data
                                }
                                
                                scanAssetLicenses {
                                    licenseId
                                    name
                                    category
                                    rejected
                                    body
                                    
                                    license {
                                        licenseId
                                        name
                                        spdxId
                                        body
                                        description
                                        notes
                                        
                                        category
                                        style
                                        publicationYear
                                        type
                                        compatible
                                        incompatible
                                        
                                        isOrgLicense
                                        
                                        attributes {
                                            name
                                            type
                                            description
                                        }
                                    }
                                }
                                
                                scanAssetCopyrights {
                                    orgId
                                    scanId
                                    scanAssetId
                                    scanAssetMatchId
                                    copyrightText
                                    discoveryType
                                    auditorUsername
                                    auditorFullName
                                    auditDate
                                    rejected
                                }
                                
                                
                                scanAssetMatches {
                                    scanId
                                    scanAssetId
                                    assetMatchId
                                    name
                                    
                                    rejected
                                    reviewed
                                    auditorFullName
                                    auditorRecommends
                                    auditDate
                                    
                                    percentMatch
                                    percentCopied
                                    assetSize
                                    repositoryPath
                                    
                                    earliestReleaseVersion
                                    latestReleaseVersion
                                    
                                    assetRepositoryUrl {
                                        data
                                    }
                                    
                                    scanAssetMatchLicenses {
                                        licenseId
                                        name
                                        category
                                        rejected
                                        body
                                        
                                        license {
                                            licenseId
                                            name
                                            spdxId
                                            body
                                            description
                                            notes
                                            
                                            category
                                            style
                                            publicationYear
                                            type
                                            compatible
                                            incompatible
                                            
                                            isOrgLicense
                                            
                                            attributes {
                                                name
                                                type
                                                description
                                            }
                                        }
                                    }
                                
                                    matchCopyrights {
                                        copyrightText
                                        discoveryType
                                        auditorUsername
                                        auditorFullName
                                        auditDate
                                        rejected
                                    }
                                    
                                    matchRepository {
                                        repositoryCode
                                        repositoryOwner
                                        repositoryName
                                    }
                                    
                                    matchGroups {
                                        edges {
                                            node {
                                                name
                                                assetMatchId
                                                earliestReleaseDate
                                                earliestReleaseVersion
                                                latestReleaseDate
                                                latestReleaseVersion
                                                licensesByMatchGroup {
                                                    licenseId
                                                    name
                                                    spdxId
                                                    body
                                                    description
                                                    notes
                                                    
                                                    category
                                                    style
                                                    publicationYear
                                                    type
                                                    compatible
                                                    incompatible
                                                    
                                                    isOrgLicense
                                                    
                                                    customerLicenseId
                                                    
                                                    attributes {
                                                        name
                                                        type
                                                        description
                                                    }
                                                }
                                                copyrights
                                                assetRepositoryUrl {
                                                    data
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
          `,
      fetchPolicy
    );
  }

  public assetListForRepo(
    entityId: string,
    scanId: string,
    assetPath: string,
    repositoryId: string,
    graphQLPage: GraphQLPage,
    filter?: string
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters('', graphQLPage);
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    const params2: string = repositoryId
      ? `repositoryId: "${repositoryId}"`
      : '';
    const filterParams: string = filter ? `, filter: "${filter}"` : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      /*eslint-disable */
      gql`
              query {
                assetListForRepo(entityId: "${entityId}"${params}, ${params2} ${filterParams} ${firstArg}${lastArg}${afterArg}${beforeArg}) {
                    edges {
                        node {
                            orgId
                            scanId
                            scanAssetId
                            name
                            auditorFullName
                            otCount
                            reviewed
                            ignored
                            rejected
                            status
                            workspacePath
                            matchCount
                            percentEmbedded
                            averagePercentCopied
                            projectPath
                            matchScore
                            size
                            assetSize
                            projectId
                            assetRepositoryUrl {
                                data
                            }
                            policyViolationNotation {
                              policyId
                              policyName
                            }
                            scanAssetMatchesByRepo(${params2}) {
                                scanId
                                scanAssetId
                                assetMatchId
                                name
                                
                                rejected
                                reviewed
                                ignored
                                auditorFullName
                                auditorRecommends
                                auditDate
                                
                                percentMatch
                                percentCopied
                                assetSize
                                repositoryPath
                                
                                earliestReleaseVersion
                                latestReleaseVersion
                                policyViolationNotation {
                                  policyId
                                  policyName
                                }
                                assetRepositoryUrl {
                                    data
                                }
                                
                                scanAssetMatchLicenses {
                                    licenseId
                                    name
                                    category
                                    rejected
                                    body
                                    attributionStatus
                                    isOrgLicense
                                    license {
                                        licenseId
                                        name
                                        spdxId
                                        body
                                        description
                                        notes
                                        needIncludeInCode
                                        category
                                        style
                                        publicationYear
                                        type
                                        compatible
                                        incompatible
                                        
                                        isOrgLicense
                                        customerLicenseId
                                        
                                        attributes {
                                            name
                                            type
                                            description
                                        }
                                    }
                                }
                            
                                matchCopyrights {
                                    copyrightText
                                    discoveryType
                                    auditorUsername
                                    auditorFullName
                                    auditDate
                                    rejected
                                }
                                
                                matchRepository {
                                    repositoryCode
                                    repositoryOwner
                                    repositoryName
                                }
                                
                                matchGroups {
                                    edges {
                                        node {
                                            name
                                            assetMatchId
                                            earliestReleaseDate
                                            earliestReleaseVersion
                                            latestReleaseDate
                                            latestReleaseVersion
                                            licensesByMatchGroup {
                                                licenseId
                                                name
                                                spdxId
                                                body
                                                description
                                                notes
                                                
                                                category
                                                style
                                                publicationYear
                                                type
                                                compatible
                                                incompatible
                                                
                                                isOrgLicense
                                                
                                                customerLicenseId
                                                
                                                attributes {
                                                    name
                                                    type
                                                    description
                                                }
                                            }
                                            copyrights
                                            assetRepositoryUrl {
                                                data
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                      }
                }
            }
                `,
      'no-cache'
    );
    /*eslint-enable */
  }

  public assetListForPath(
    entityId: string,
    scanId: string,
    assetPath: string,
    graphQLPage: GraphQLPage,
    filter?: string
  ) {
    const { filterArg, firstArg, lastArg, afterArg, beforeArg } =
      this.utilsService.getAPIParameters('', graphQLPage);
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';

    const filterParams: string = filter ? `, filter: "${filter}"` : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
            query {
                assetListForPath(entityId: "${entityId}"${params} ${filterParams} ${firstArg}${lastArg}${afterArg}${beforeArg}) {
                    edges {
                        node {
                            orgId
                            projectId
                            scanId
                            scanAssetId
                            otCount
                            name
                            auditorFullName
                            reviewed
                            ignored
                            rejected
                            status
                            workspacePath
                            matchCount
                            matchScore
                            percentEmbedded
                            averagePercentCopied
                            projectPath
                            assetSize
                            assetType
                            assetRepositoryUrl {
                                data
                            }
                            policyViolationNotation {
                              policyId
                              policyName
                            }
                            scanAssetMatches {
                                scanId
                                scanAssetId
                                assetMatchId
                                name
                                rejected
                                reviewed
                                ignored
                                auditorFullName
                                auditorRecommends
                                auditDate
                                percentMatch
                                matchScore
                                percentCopied
                                assetSize
                                repositoryPath
                                earliestReleaseVersion
                                latestReleaseVersion
                                
                                assetRepositoryUrl {
                                    data
                                }
                                policyViolationNotation {
                                  policyId
                                  policyName
                                }
                                scanAssetMatchLicenses {
                                    licenseId
                                    name
                                    category
                                    rejected
                                    attributionStatus
                                    isOrgLicense
                                    license {
                                        licenseId
                                        name
                                        spdxId
                                        description
                                        notes
                                        category
                                        style
                                        publicationYear
                                        type
                                        body
                                        needIncludeInCode
                                        customerLicenseId
                                        attributes {
                                          description
                                          name
                                          type
                                        }
                                    }
                                }
                            
                                matchCopyrights {
                                    copyrightText
                                    discoveryType
                                    auditorUsername
                                    auditorFullName
                                    auditDate
                                    rejected
                                }
                                
                                matchRepository {
                                    repositoryCode
                                    repositoryOwner
                                    repositoryName
                                }
                                
                                matchGroups {
                                    edges {
                                        node {
                                            name
                                            assetMatchId
                                            earliestReleaseDate
                                            earliestReleaseVersion
                                            latestReleaseDate
                                            latestReleaseVersion
                                            licensesByMatchGroup {
                                                licenseId
                                                name
                                                spdxId
                                                description
                                                notes
                                                category
                                                style
                                                publicationYear
                                                type
                                                
                                                isOrgLicense
                                                
                                                customerLicenseId
                                                
                                                attributes {
                                                    name
                                                    type
                                                    description
                                                }
                                            }
                                            copyrights
                                            assetRepositoryUrl {
                                                data
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                      }
                }
            }
                `,
      'no-cache'
    );
  }

  public reviewAllByAssetCard(
    reviewData: CardReviewData,
    type: 'approve' | 'reject' | 'ignore' | 'revert'
  ): Observable<FetchResult<unknown>> {
    switch (type) {
      case 'approve':
        return this.approveAllByCard(reviewData);
      case 'reject':
        return this.rejectAllByCard(reviewData);
      case 'ignore':
        return this.ignoreAllByCard(reviewData);
      default:
        break;
    }
  }

  public approveAllByCard(reviewData: CardReviewData) {
    const filter: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReqForMutation(gql`
            mutation {
              approveAllByCard(
                    entityId: "${reviewData.entityId}",
                    assetPath: "${reviewData.assetPath}",
                    scanId: "${reviewData.scanId}",
                    repositoryId: "${reviewData.repositoryId}"
                    ${filter}
                )
            }`);
    //approveAllByCard
  }

  public ignoreAllByCard(reviewData: CardReviewData) {
    const filter: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReqForMutation(gql`
            mutation {
              ignoreAllByCard(
                    entityId: "${reviewData.entityId}",
                    assetPath: "${reviewData.assetPath}",
                    scanId: "${reviewData.scanId}",
                    repositoryId: "${reviewData.repositoryId}"
                    ${filter}
                )
            }`);
    //ignoreAllByCard
  }

  public rejectAllByCard(reviewData: CardReviewData) {
    const filter: string = reviewData.filter
      ? `, filter: "${reviewData.filter}"`
      : '';
    return this.coreGraphQLService.coreGQLReqForMutation(gql`
            mutation {
              rejectAllByCard(
                    entityId: "${reviewData.entityId}",
                    assetPath: "${reviewData.assetPath}",
                    scanId: "${reviewData.scanId}",
                    repositoryId: "${reviewData.repositoryId}"
                    ${filter}
                )
            }`);
    //rejectAllByCard
  }

  public assetsMetricsForPath(
    entityId: string,
    scanId: string,
    assetPath: string
  ) {
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          assetsMetricsForPath(entityId: "${entityId}"${params})
        }`,
      'no-cache'
    );
  }

  public assetsMetricsForPathAndRepo(
    entityId: string,
    scanId: string,
    assetPath: string,
    repositoryId: string
  ) {
    let params: string = scanId ? `, scanId: "${scanId}"` : '';
    params += assetPath ? `, assetPath: "${assetPath}"` : '';
    params += repositoryId ? `, repositoryId: "${repositoryId}"` : '';
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          assetsMetricsForPathAndRepo(entityId: "${entityId}"${params})
        }`,
      'no-cache'
    );
  }

  public addLicenseToAsset(
    assetMatchId: Array<string>,
    license: License,
    scanAssetId: string,
    scanId: string
  ) {
    const mappedLicense: {
      body: string;
      licenseCategory: string;
      licenseId: string;
      name: string;
    } = {
      body: license.body,
      licenseCategory: license.category,
      licenseId: license.licenseId,
      name: license.name,
    };
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation AddLicenseToScanAssetMatches(
          $assetMatchId: [String]
          $license: LicenseDataInput
          $scanAssetId: String
          $scanId: UUID
        ) {
          addLicenseToScanAssetMatches(
            assetMatchId: $assetMatchId
            license: $license
            scanAssetId: $scanAssetId
            scanId: $scanId
          )
        }
      `,
      {
        assetMatchId,
        license: mappedLicense,
        scanAssetId,
        scanId,
      }
    );
  }

  public rejectLicense(
    assetMatchIds: Array<string>,
    licenseId: string,
    scanAssetId: string,
    scanId: string
  ) {
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation RejectScanAssetMatchesLicense(
          $assetMatchIds: [String]
          $licenseId: String
          $scanAssetId: String
          $scanId: UUID
        ) {
          rejectScanAssetMatchesLicense(
            assetMatchIds: $assetMatchIds
            licenseId: $licenseId
            scanAssetId: $scanAssetId
            scanId: $scanId
          )
        }
      `,
      {
        assetMatchIds,
        licenseId,
        scanAssetId,
        scanId,
      }
    );
  }

  public updateLicenseOfScanAssetMatches(request) {
    return this.coreGraphQLService.coreGQLReqForMutation<any>(
      gql(`
        mutation (
          $request: ScopedLicenseScanAssetMatchAssignmentRequestInput) {
          updateLicenseOfScanAssetMatches(request: $request) {
            licenseId
            name
            category
            isOrgLicense
            orgId
            rejected
            attributionStatus
            license {
              licenseId
              name
              spdxId
              body
              description
              notes
              category
              style
              publicationYear
              type
              compatible
              incompatible
              isOrgLicense
              customerLicenseId
              attributes {
                name
                type
                description
              }
            }
          }
        }
      `),
      { request }
    );
  }
}
