import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-policy-violations',
  templateUrl: './policy-violations.component.html',
  styleUrls: ['./policy-violations.component.scss'],
})
export class PolicyViolationsComponent {
  @Input() public policies: Array<{ policyName: string; policyId: string }> =
    [];
  @Input() public stylesClass: string;

  @ViewChild('p') public popover: TemplateRef<any>;

  public constructor(private router: Router) {}

  public closePopover(): void {
    (this.popover as any).close();
  }

  public goToPolicy(id: string) {
    this.router.navigate(['/security/policy/show/' + id]);
  }
}
