<div>
  <ul
    class="navbar-nav ml-auto"
    style="
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: #202932;
    "
  >
    <li
      class="free-trial"
      *ngIf="subscriptionService.trialUser && !subscriptionService.trialExpired"
    >
      Free trial ({{ subscriptionService.trialDaysRemaining }} days remaining)
    </li>

    <li
      class="free-trial"
      *ngIf="
        subscriptionService.trialExpired && !subscriptionService.subscribedUser
      "
    >
      Free trial expired
    </li>

    <li
      *ngIf="!isHybrid && subscriptionService.trialUser"
      class="upgrade-button"
    >
      <a
        routerLink="/subscription/subscribe"
        title="Upgrade now"
        style="color: black; height: 50px; background-color: red"
      >
        <div
          style="
            color: black;
            height: 40px;
            width: 80px;
            border-radius: 5px;
            padding-top: 10px;
            text-align: center;
            background-color: yellow;
            line-height: 20px;
          "
        >
          Upgrade
        </div>
      </a>
    </li>
    <li>
      <a href="https://docs.threatrix.io/" title="Documentation" target="_blank"
        ><i class="fad fa-books document-logo m-r-5" style="font-size: 25px"></i
      ></a>
    </li>
    <!-- <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <a href="javascript:" class="m-r-10">mark as read</a>
            <a href="javascript:">clear all</a>
          </div>
        </div>
        <ul class="noti-body">
          <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div>
      </div>
    </div>
  </li> -->
    <li>
      <div class="dropdown drp-user" ngbDropdown placement="auto">
        <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
          <img
            src="{{
              !!currentUser.avatarUrl
                ? currentUser.avatarUrl
                : 'assets/images/user/no-avatar.png'
            }}"
            class="img-radius"
            alt="User-Profile-Image"
            style="
              max-width: 30px;
              max-height: 30px;
              position: relative;
              left: -8px;
              top: -2px;
            "
          />
        </a>
        <div
          class="dropdown-menu dropdown-menu-right profile-notification"
          ngbDropdownMenu
        >
          <div class="pro-head">
            <img
              src="{{
                !!currentUser.avatarUrl
                  ? currentUser.avatarUrl
                  : 'assets/images/user/no-avatar.png'
              }}"
              (click)="gotoProfile()"
              class="img-radius cursor-pointer"
              alt="User-Profile-Image"
            />
            <span (click)="gotoProfile()" class="cursor-pointer">{{
              !!currentUser.fname
                ? currentUser.fname
                : '' + ' ' + !!currentUser.lname
                ? currentUser.lname
                : ''
            }}</span>
            <a
              href="javascript:"
              class="dud-logout"
              title="Logout"
              (click)="logout()"
            >
              <i class="feather icon-log-out"></i>
            </a>
          </div>
          <ul class="pro-body">
            <li>
              <a
                href="javascript:"
                class="dropdown-item"
                (click)="gotoProfile()"
                ><i class="feather icon-user"></i> Profile</a
              >
            </li>
            <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li> -->
            <li>
              <a
                href="javascript:"
                class="dropdown-item"
                (click)="inviteUser(inviteUrlDialog)"
                ><i class="feather icon-users"></i> Invite user</a
              >
            </li>
            <li>
              <a href="javascript:" class="dropdown-item" (click)="logout()"
                ><i class="feather icon-log-out"></i> Logout</a
              >
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>

  <ng-template #inviteUrlDialog let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Invite user</h4>
    </div>
    <div class="modal-body">
      <div>
        <label>Invite URL</label>
        <textarea
          disabled
          name="inviteUrl"
          style="width: 100%"
          class="form-control input-contrast comment-form-textarea js-comment-field"
          value="{{ inviteUrl }}"
        ></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-light"
        (click)="modal.close('click')"
      >
        Close
      </button>
    </div>
  </ng-template>
</div>
