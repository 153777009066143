<ngb-alert *ngIf="showAlert" [type]="'warning'" (close)="showAlert = false"
  >SCM related actions are not available for this project scan.</ngb-alert
>
<div class="dash-tab-form">
  <div style="display: flex; justify-content: space-between">
    <mat-form-field
      appearance="outline"
      color="accent"
      class="filter-input big"
    >
      <mat-label>Name</mat-label>
      <input
        id="nameSearch"
        matInput
        [ngModel]="getColumnFilterValue('Name')"
        (ngModelChange)="onFilterColumn('Name', $event, 'nameSearch')"
      />
    </mat-form-field>
    <!-- 
    <mat-form-field appearance="outline">
      <mat-label>Outline form field</mat-label>
      <input matInput placeholder="Placeholder" />
      <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      <mat-hint>Hint</mat-hint>
    </mat-form-field> -->

    <!-- <div class="form-group" style="width: 15%">
      <input
        id="nameSearch"
        class="form-control"
        name="search-name"
        placeholder="Name"
        [value]="getColumnFilterValue('Name')"
        (input)="onFilterColumn('Name', $event, 'nameSearch')"
      />
    </div> -->

    <mat-form-field
      appearance="outline"
      color="accent"
      class="filter-input big"
    >
      <mat-label>Location</mat-label>
      <mat-select
        id="locationSearch"
        [ngModel]="getColumnFilterValue('Location')"
        (ngModelChange)="onFilterColumn('Location', $event, 'locationSearch')"
        disableOptionCentering
      >
        <mat-option value="ALL">- All -</mat-option>
        <mat-option value="DEPENDENCY_FILE">DEPENDENCY FILE</mat-option>
        <mat-option value="DRIVE">DRIVE</mat-option>
        <mat-option value="STATIC_REF">STATIC REF</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div class="form-group" style="width: 13%">
      <select
        id="locationSearch"
        class="form-control custom-select search-dropdown-w"
        [value]="getColumnFilterValue('Location')"
        (change)="onFilterColumn('Location', $event, 'locationSearch')"
      >
        <option value="ALL" selected>- All -</option>
        <option value="DEPENDENCY_FILE">DEPENDENCY FILE</option>
        <option value="DRIVE">DRIVE</option>
        <option value="STATIC_REF">STATIC REF</option>
      </select>
    </div> -->

    <mat-form-field
      appearance="outline"
      color="accent"
      class="filter-input big"
    >
      <mat-label>Type</mat-label>
      <mat-select
        id="typeSearch"
        [ngModel]="getColumnFilterValue('Type')"
        (ngModelChange)="onFilterColumn('Type', $event, 'typeSearch')"
        disableOptionCentering
      >
        <mat-option value="ALL">- All -</mat-option>
        <mat-option value="LIBRARY">LIBRARY</mat-option>
        <mat-option value="ASSET">ASSET</mat-option>
      </mat-select>
    </mat-form-field>
    <!--     
    <div class="form-group" style="width: 13%">
      <select
        id="typeSearch"
        class="form-control custom-select search-dropdown-w"
        [value]="getColumnFilterValue('Type')"
        (change)="onFilterColumn('Type', $event, 'typeSearch')"
      >
        <option value="ALL" selected>- All -</option>
        <option value="LIBRARY">LIBRARY</option>
        <option value="ASSET">ASSET</option>
      </select>
    </div> -->

    <!-- <div class="form-group" style="width: 13%"> -->
    <mat-form-field
      appearance="outline"
      color="accent"
      class="filter-input big"
    >
      <mat-label>Discovery</mat-label>
      <mat-select
        id="discoverySearch"
        [ngModel]="getColumnFilterValue('Discovery')"
        (ngModelChange)="onFilterColumn('Discovery', $event, 'discoverySearch')"
        disableOptionCentering
      >
        <mat-option value="ALL">- All -</mat-option>
        <mat-option value="DECLARED">DECLARED</mat-option>
        <mat-option value="DISCOVERED">DISCOVERED</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <select
        id="discoverySearch"
        class="form-control custom-select search-dropdown-w"
        [value]="getColumnFilterValue('Discovery')"
        (change)="onFilterColumn('Discovery', $event, 'discoverySearch')"
      >
        <option value="ALL" selected>- All -</option>
        <option value="DECLARED">DECLARED</option>
        <option value="DISCOVERED">DISCOVERED</option>
      </select> -->
    <!-- </div> -->

    <mat-form-field
      appearance="outline"
      color="accent"
      style="width: 7%; height: 43px; margin-right: 5px"
    >
      <mat-label>Min CVSS3</mat-label>
      <mat-select
        id="cvss3Search"
        [ngModel]="getColumnFilterValue('CVSS3')"
        (ngModelChange)="onFilterColumn('CVSS3', $event, 'cvss3Search')"
        disableOptionCentering
      >
        <mat-option value="ALL">- All -</mat-option>
        <mat-option value="1">1.0</mat-option>
        <mat-option value="2">2.0</mat-option>
        <mat-option value="3">3.0</mat-option>
        <mat-option value="4">4.0</mat-option>
        <mat-option value="5">5.0</mat-option>
        <mat-option value="6">6.0</mat-option>
        <mat-option value="7">7.0</mat-option>
        <mat-option value="8">8.0</mat-option>
        <mat-option value="9">9.0</mat-option>
        <mat-option value="10">10.0</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      color="accent"
      style="width: 7%; height: 43px; margin-right: 5px"
    >
      <mat-label>Min EPSS</mat-label>
      <mat-select
        id="epssSearch"
        [ngModel]="getColumnFilterValue('EPSS')"
        (ngModelChange)="onFilterColumn('EPSS', $event, 'epssSearch')"
        disableOptionCentering
      >
        <mat-option value="ALL">- All -</mat-option>
        <mat-option value="10">10%</mat-option>
        <mat-option value="20">20%</mat-option>
        <mat-option value="30">30%</mat-option>
        <mat-option value="40">40%</mat-option>
        <mat-option value="50">50%</mat-option>
        <mat-option value="60">60%</mat-option>
        <mat-option value="70">70%</mat-option>
        <mat-option value="80">80%</mat-option>
        <mat-option value="90">90%</mat-option>
        <mat-option value="100">100%</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      color="accent"
      style="width: 7%; height: 43px"
    >
      <mat-label>Depth</mat-label>
      <mat-select
        id="depthSearch"
        [ngModel]="getColumnFilterValue('MinDepth')"
        (ngModelChange)="onFilterColumn('MinDepth', $event, 'depthSearch')"
        disableOptionCentering
      >
        <mat-option value="ALL">- All -</mat-option>
        <mat-option value="1">1</mat-option>
        <mat-option value="2">2</mat-option>
        <mat-option value="3">3</mat-option>
        <mat-option value="4+">4+</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div class="form-group" style="width: 10%">
      <select
        id="depthSearch"
        class="form-control custom-select search-dropdown-w"
        [value]="getColumnFilterValue('MinDepth')"
        (change)="onFilterColumn('MinDepth', $event, 'depthSearch')"
      >
        <option value="" disabled selected>Depth</option>
        <option value="ALL">- All -</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4+">4+</option>
      </select>
    </div> -->

    <div class="actions-section">
      <div class="actions-section-radios">
        <div class="checkbox checkbox-info d-inline p-0 mr-3">
          <input
            id="checkbox-01"
            type="checkbox"
            name="checkbox-01"
            [checked]="isInternal"
            (change)="onFilterColumn('Internal', $event)"
          />
          <label class="cr mb-0" for="checkbox-01">Internal</label>
        </div>

        <div class="checkbox checkbox-info d-inline p-0 mr-3">
          <input
            id="checkbox-02"
            type="checkbox"
            name="checkbox-02"
            [checked]="isVulnerabilities"
            (change)="onFilterColumn('Vulnerabilities', $event)"
          />
          <label class="cr mb-0" for="checkbox-02">Vulnerabilities</label>
        </div>

        <div class="checkbox checkbox-info d-inline p-0 mr-3">
          <input
            id="checkbox-03"
            type="checkbox"
            name="checkbox-03"
            [checked]="isHasFix"
            (change)="onFilterColumn('HasFix', $event)"
          />
          <label class="cr mb-0" for="checkbox-03">Has fix</label>
        </div>
      </div>

      <button class="btn btn-primary" (click)="clearFilters($event)">
        Clear filters
      </button>
      <i *ngIf="loading" class="fa-duotone fa-spinner fa-spin"></i>
    </div>
  </div>
</div>

<div class="text-center center-table-progressbar" *ngIf="isDisablePaggination">
  <mat-progress-spinner
    strokeWidth="5"
    [diameter]="40"
    [mode]="'indeterminate'"
  >
  </mat-progress-spinner>
</div>

<ngb-alert
  class="no-components-alert"
  *ngIf="filtered && componentDetails?.components?.edges?.length === 0"
  [type]="'warning'"
  >No components found for the selected filters. Please, change filters or clear
  them.</ngb-alert
>

<div class="vulnerabilites-data" *ngIf="!isDisablePaggination">
  <ul>
    <li
      *ngFor="
        let componentData of componentDetails.components?.edges;
        let i = index
      "
      class="cursor-pointer data-item {{
        getMaxServity(componentData?.node?.vulnerabilities)
      }}"
      (click)="gotoDetails(componentData?.node?.componentId)"
    >
      <div class="row">
        <div class="col-sm-5 col-xs-12">
          <div class="vulner-data-lt">
            <div class="data-tag-ttl">
              <p class="data-hdng ml-0">
                <i
                  [appRepoIcon]="componentData?.node['dependencyManagerType']"
                  [placement]="'auto'"
                  [ngbTooltip]="
                    componentData?.node['workspaceRelativeFilePath']
                  "
                ></i>
                {{ componentData?.node | componentName }}
              </p>
            </div>
            <div class="data-row row">
              <div class="col-sm-4 col-xs-12">
                <div class="info-cols flex-column align-items-start">
                  <small>Provenance</small> External
                </div>
              </div>

              <div class="col-sm-4 col-xs-12">
                <div class="info-cols flex-column align-items-start">
                  <small>Discovered in</small>
                  {{ componentData?.node?.componentLocation | discover }}
                </div>
              </div>
              <div class="col-sm-4 col-xs-12">
                <div class="info-cols flex-column align-items-start">
                  <small>Depth</small>
                  <span
                    *ngIf="
                      componentData?.node?.minDepth === -1;
                      else depthValue
                    "
                    >unknown</span
                  >
                  <ng-template #depthValue>{{
                    componentData?.node?.minDepth || '-'
                  }}</ng-template>
                </div>
              </div>
            </div>

            <div class="data-row row">
              <div class="col-sm-4 col-xs-12">
                <div class="info-cols flex-column align-items-start">
                  <small>Type</small> {{ componentData?.node?.componentType }}
                </div>
              </div>

              <div class="col-sm-4 col-xs-12">
                <div class="info-cols flex-column align-items-start">
                  <small>Discovery Type</small>
                  {{ componentData?.node?.componentDiscoveryMethod }}
                </div>
              </div>

              <div
                class="col-sm-4 col-xs-12"
                *ngIf="
                  authorizationService.hasPermissions('FEATURE_PREVIEW') &&
                    componentData?.node['supplyChainRiskScore']
                    | parseJson as riskScoreData
                "
              >
                <div
                  class="info-cols flex-column align-items-start"
                  (click)="$event.stopPropagation()"
                  [ngbPopover]="riskDetails"
                  [container]="'body'"
                  popoverClass="risk-popover"
                  #p="ngbPopover"
                  [autoClose]="'outside'"
                  *ngIf="riskScoreData.supplyChainRiskScore.value > 0.0001"
                >
                  <small>Supply Chain Trust Score</small>
                  <span class="risk-score"
                    ><span
                      class="severity-badge"
                      [ngClass]="{
                        crimson:
                          riskScoreData.supplyChainRiskScore.value >= 0 &&
                          riskScoreData.supplyChainRiskScore.value < 10,
                        red:
                          riskScoreData.supplyChainRiskScore.value >= 10 &&
                          riskScoreData.supplyChainRiskScore.value < 20,
                        orange:
                          riskScoreData.supplyChainRiskScore.value >= 20 &&
                          riskScoreData.supplyChainRiskScore.value < 30,
                        yellow:
                          riskScoreData.supplyChainRiskScore.value >= 30 &&
                          riskScoreData.supplyChainRiskScore.value < 40,
                        blue:
                          riskScoreData.supplyChainRiskScore.value >= 40 &&
                          riskScoreData.supplyChainRiskScore.value < 60,
                        green: riskScoreData.supplyChainRiskScore.value >= 60
                      }"
                    ></span>
                    {{ riskScoreData.supplyChainRiskScore.value }}</span
                  >
                  <ng-template #riskDetails>
                    <app-risk-score-popup
                      [componentData]="componentData"
                      [p]="p"
                      [riskScoreData]="riskScoreData"
                    ></app-risk-score-popup>
                    <!-- <span class="main-title">Component Risk Analysis</span>
                    <i
                      class="fa fa-times"
                      (click)="$event.stopPropagation(); p.close()"
                    ></i>
                    <div class="risk-body">
                      <div>
                        <span class="risk-title">Group:</span>
                        <span class="component-data">{{
                          componentData?.node.group
                        }}</span>
                      </div>
                      <div>
                        <span class="risk-title">Project:</span>
                        <span class="component-data">
                          {{ componentData?.node.name }}</span
                        >
                      </div>
                      <div>
                        <span class="risk-title">Version:</span>
                        <span class="component-data">
                          {{ componentData?.node.version }}</span
                        >
                      </div>

                      <div class="results-bubble medium">
                        <span class="results-bubble-title"
                          >Supply Chain Risk</span
                        >
                        <div
                          class="results-bubble-inner"
                          [ngClass]="{
                            yellow:
                              riskScoreData.supplyChainRiskScore.value >= 4 &&
                              riskScoreData.supplyChainRiskScore.value < 6,
                            orange:
                              riskScoreData.supplyChainRiskScore.value >= 6 &&
                              riskScoreData.supplyChainRiskScore.value < 8,
                            red: riskScoreData.supplyChainRiskScore.value === 8,
                            crimson: riskScoreData.supplyChainRiskScore.value > 8
                          }"
                        >
                          <span class="results-bubble-inner-number">{{
                            riskScoreData.supplyChainRiskScore.value
                          }}</span>
                        </div>
                      </div>

                      <div
                        class="risk-line"
                        [ngClass]="{
                          yellow: score.value >= 4 && score.value < 6,
                          orange: score.value >= 6 && score.value < 8,
                          red: score.value === 8,
                          crimson: score.value > 8
                        }"
                        *ngFor="let score of riskScoreData.scores"
                      >
                        <span class="risk-line-title">{{ score.label }}</span>
                        <div style="display: flex">{{ score.value }}</div>
                        <div></div>
                      </div>
                    </div> -->
                  </ng-template>
                </div>

                <div
                  class="info-cols flex-column align-items-start"
                  (click)="$event.stopPropagation()"
                  *ngIf="riskScoreData.supplyChainRiskScore.value <= 0.0001"
                >
                  <small>Supply Chain Trust Score</small>
                  <span
                    class="risk-score"
                    *ngIf="riskScoreData.supplyChainRiskScore.value === 0"
                    ><span class="severity-badge"></span> N/A</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="vulner-data-lt" style="margin-top: 29px">
            <span
              class="titles-small blue no-whitespace"
              style="margin-bottom: 2px"
              >Licenses</span
            >
            <ng-container
              *ngIf="
                componentData?.node?.licenses?.edges.length > 0;
                else noLicenses
              "
            >
              <div
                *ngFor="
                  let licenseData of componentData?.node?.licenses?.edges;
                  index as i
                "
                class="data-row row mt-0"
              >
                <div *ngIf="i < 2" class="col-sm-12 col-xs-12">
                  <div class="info-cols flex-column align-items-start">
                    <small *ngIf="!!licenseData?.node?.licenseDiscovery"
                      >{{
                        licenseData?.node?.licenseDiscovery
                      }}
                      License(s)</small
                    >
                    <div class="tag-row d-flex align-items-center mt-1">
                      <label
                        class="mr-2"
                        style="font-size: 20px; text-align: center"
                      >
                        <span
                          *ngIf="
                            licenseData?.node?.category === 'COPYLEFT_STRONG'
                          "
                          title="Copyleft Strong"
                          class="badge m-r-5 badge-light-danger"
                          >COPYLEFT STRONG</span
                        >
                        <span
                          *ngIf="
                            licenseData?.node?.category === 'COPYLEFT_WEAK'
                          "
                          title="Copyleft Weak"
                          class="badge m-r-5 badge-light-warning"
                          >COPYLEFT WEAK</span
                        >
                        <span
                          *ngIf="
                            licenseData?.node?.category === 'COPYLEFT_PARTIAL'
                          "
                          title="Copyleft Partial"
                          class="badge m-r-5 badge-light-warning"
                          >COPYLEFT PARTIAL</span
                        >
                        <span
                          *ngIf="
                            licenseData?.node?.category === 'COPYLEFT_LIMITED'
                          "
                          title="Copyleft Limited"
                          class="badge m-r-5 badge-light-warning"
                          >COPYLEFT LIMITED</span
                        >
                        <span
                          *ngIf="licenseData?.node?.category === 'COPYLEFT'"
                          title="Copyleft"
                          class="badge m-r-5 badge-light-warning"
                          >COPYLEFT</span
                        >
                        <span
                          *ngIf="licenseData?.node?.category === 'PERMISSIVE'"
                          title="Permissive"
                          class="badge m-r-5 badge-light-success"
                          >PERMISSIVE</span
                        >
                        <span
                          *ngIf="
                            licenseData?.node?.category === 'PROPRIETARY_FREE'
                          "
                          title="Proprietary Free"
                          class="badge m-r-5 badge-light-warning"
                          >PROPRIETARY_FREE</span
                        >
                        <span
                          *ngIf="
                            licenseData?.node?.category === 'PUBLIC_DOMAIN'
                          "
                          title="Permissive"
                          class="badge m-r-5 badge-light-success f-18"
                          >PUBLIC DOMAIN</span
                        >
                        <span
                          *ngIf="licenseData?.node?.category === 'UNDEFINED'"
                          title="Undefined"
                          class="badge m-r-5 badge-light-warning f-18"
                          >UNDEFINED</span
                        > </label
                      >{{ licenseData?.node?.name }}
                    </div>
                  </div>
                </div>
              </div>

              <p
                class="text-primary cursor-pointer"
                *ngIf="componentData?.node?.licenses?.edges.length > 2"
              >
                more...
              </p>
            </ng-container>
            <ng-template #noLicenses>
              <br /><span style="height: 48px">No licenses found</span>
            </ng-template>
          </div>
        </div>

        <div class="col-sm-4 col-xs-12">
          <!-- START -->
          <div class="vertical" style="margin-top: 29px">
            <span
              class="titles-small blue no-whitespace"
              style="margin-bottom: 2px"
              >Vulnerabilities</span
            >
            <ng-container
              *ngIf="
                componentData?.node?.vulnerabilities?.edges.length >= 1;
                else noVulnerabilities
              "
            >
              <div class="horizontal">
                <ng-container
                  *ngIf="
                    componentData?.node?.vulnerabilities?.edges[
                      'vulnerabilitiesSeveretyTotal'
                    ] as severityTotal
                  "
                >
                  <div
                    class="bubble vertical centered critical"
                    *ngIf="severityTotal.CRITICAL"
                    (click)="
                      openVulnerabilitiesInfoDialogComponent(
                        componentData.node,
                        $event
                      )
                    "
                  >
                    <span>CRITICAL</span>
                    <span class="number">{{ severityTotal.CRITICAL }}</span>
                  </div>
                  <div
                    class="bubble vertical centered high"
                    *ngIf="severityTotal.HIGH"
                    (click)="
                      openVulnerabilitiesInfoDialogComponent(
                        componentData.node,
                        $event
                      )
                    "
                  >
                    <span>HIGH</span>
                    <span class="number">{{ severityTotal.HIGH }}</span>
                  </div>
                  <div
                    class="bubble vertical centered medium"
                    *ngIf="severityTotal.MEDIUM"
                    (click)="
                      openVulnerabilitiesInfoDialogComponent(
                        componentData.node,
                        $event
                      )
                    "
                  >
                    <span>MEDIUM</span>
                    <span class="number">{{ severityTotal.MEDIUM }}</span>
                  </div>
                  <div
                    class="bubble vertical centered low"
                    *ngIf="severityTotal.LOW"
                    (click)="
                      openVulnerabilitiesInfoDialogComponent(
                        componentData.node,
                        $event
                      )
                    "
                  >
                    <span>LOW</span>
                    <span class="number">{{ severityTotal.LOW }}</span>
                  </div>
                  <div
                    class="bubble vertical centered info"
                    *ngIf="severityTotal.INFO"
                    (click)="
                      openVulnerabilitiesInfoDialogComponent(
                        componentData.node,
                        $event
                      )
                    "
                  >
                    <span>INFO</span>
                    <span class="number">{{ severityTotal.INFO }}</span>
                  </div>
                  <div
                    class="bubble vertical centered unassigned"
                    *ngIf="severityTotal.UNASSIGNED"
                    (click)="
                      openVulnerabilitiesInfoDialogComponent(
                        componentData.node,
                        $event
                      )
                    "
                  >
                    <span>UNSET</span>
                    <span class="number">{{ severityTotal.UNASSIGNED }}</span>
                  </div>
                  <button
                    *ngIf="
                      isScmActionsAvailableForScan &&
                      componentData.node.componentType !== 'ASSET' &&
                      !componentData.node.autofixPullRequest
                    "
                    class="btn btn-success data-cta"
                    (click)="fixVersion(componentData)"
                  >
                    Fix
                  </button>
                  <button
                    *ngIf="
                      isScmActionsAvailableForScan &&
                      componentData.node.componentType === 'ASSET' &&
                      !componentData.node.autofixPullRequest
                    "
                    disabled
                    class="btn btn-secondary data-cta"
                    ngbTooltip="Autofix is not available for asset component."
                    (click)="fixVersion(componentData)"
                  >
                    Fix
                  </button>
                  <button
                    *ngIf="
                      isScmActionsAvailableForScan &&
                      componentData.node.componentType !== 'ASSET' &&
                      componentData.node.autofixPullRequest
                    "
                    class="btn btn-info data-cta"
                    (click)="
                      $event.stopPropagation();
                      openPullRequestLink(componentData.node.autofixPullRequest)
                    "
                  >
                    Open PR
                    <i
                      style="margin-left: 3px"
                      class="fa-solid fa-arrow-up-right-from-square"
                    ></i>
                  </button>
                </ng-container>
              </div>
            </ng-container>

            <ng-template #noVulnerabilities>
              <span style="height: 48px">No vulnerabilities detected</span>
            </ng-template>
          </div>
          <!-- END -->
          <div>
            <app-policy-violations
              *ngIf="componentData.node?.policyViolationNotation?.length > 0"
              [policies]="componentData.node?.policyViolationNotation"
              [stylesClass]="'core-components'"
            ></app-policy-violations>
          </div>
          <!-- 
          <div
            class="component-fixed-box"
            *ngIf="componentData?.node?.vulnerabilities?.edges.length >= 1"
          >
            <div class="vulner-data-rt vulner-sts-data">
              <div class="info-cols">
                <small>Vulnerabilities</small>

                <ul
                  class="component-vulner-sts mt-2"
                  *ngIf="
                    componentData?.node?.vulnerabilities?.edges.length >= 1
                  "
                >
                  <li
                    *ngFor="
                      let data of uniqData(
                        componentData?.node?.vulnerabilities?.edges
                      );
                      index as i
                    "
                    class="{{ data.node.severity }}"
                  >
                    <span class="component-vulner-inn" *ngIf="i < 3">
                      <em>{{ data?.node?.severity?.charAt(0) }}</em>
                      <b
                        *ngIf="data.node.severity === 'CRITICAL'; else otherTem"
                        class="{{ data.node.severity }}"
                        >{{ data.node.vulnerabilityAlias }}</b
                      >
                      <ng-template #otherTem>
                        <span class="{{ data.node.severity }}">{{
                          data.node.vulnerabilityAlias
                        }}</span>
                      </ng-template>
                    </span>
                  </li>
                </ul>
                <p
                  triggers="mouseenter"
                  [ngbPopover]="popContent"
                  class="more-link"
                  *ngIf="componentData?.node?.vulnerabilities?.edges.length > 3"
                >
                  More
                </p>
                <ng-template #popContent>
                  <ul
                    class="component-vulner-sts mt-2"
                    *ngIf="
                      componentData?.node?.vulnerabilities?.edges.length >= 1
                    "
                  >
                    <li
                      class="high"
                      *ngFor="
                        let data of uniqData(
                          componentData?.node?.vulnerabilities?.edges
                        );
                        index as i
                      "
                    >
                      <b
                        *ngIf="data.node.severity === 'CRITICAL'; else otherTem"
                        class="{{ data.node.severity }}"
                        >{{ data.node.vulnerabilityAlias }}</b
                      >
                      <ng-template #otherTem>
                        <span class="{{ data.node.severity }}">{{
                          data.node.vulnerabilityAlias
                        }}</span>
                      </ng-template>
                    </li>
                  </ul>
                </ng-template>
                <p
                  *ngIf="
                    componentData?.node?.vulnerabilities?.edges.length == 0
                  "
                >
                  {{ componentData?.node?.name }} has no known vulnerabilities
                  yet.
                </p>
              </div>
            </div>

            <div class="vulner-data-rt">

              <div class="action-btns mt-4">
                <button
                  *ngIf="isScmActionsAvailableForScan"
                  class="btn data-cta"
                  (click)="
                    fixVersion(
                      componentData?.node?.componentId,
                      componentData?.node?.version
                    )
                  "
                >
                  Fix
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </li>
  </ul>
</div>

<div class="col-sm-12">
  <mat-paginator
    [hidePageSize]="false"
    [disabled]="isDisablePaggination"
    [length]="componentDetails?.components?.totalCount"
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="[25, 50, 100]"
    (page)="changePage($event)"
  >
  </mat-paginator>
</div>
